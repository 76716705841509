import React from "react";
import {ITrackBuffer} from "../../TrackBufferRecord";

interface Props {
    track: ITrackBuffer
    width: number,
    height: number,
    top: number,
}


export default function WaveformLabel({height, track, width, top}: Props) {

    const heightPx = `${height}px`;
    if (!(track && track.buffer)) {
        return null;
    }
    return <span style={{
        position: 'absolute',
        opacity: '0.4',
        top: `${top}px`,
        height: heightPx,
        lineHeight: heightPx,
        pointerEvents: 'none'
    }}
    >{track.name}</span>
}

