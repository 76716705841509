import {createMuiTheme, ThemeOptions,} from "@material-ui/core";
import { blueGrey, grey, } from "@material-ui/core/colors";

const defaultThemeOptions:ThemeOptions={

    typography: {
        fontFamily: "Fira Sans, sans-serif",
        h1: {
            color: "rgba (255,255,255)"
        }
    },
    palette: {
        secondary: blueGrey,
        primary: {
            light: '#7986cb',
            main: '#00599d',
            dark: '#303f9f'
        },
        background: {default: '#ffffff'}
    },
    overrides: {
        MuiButton: {
            // Name of the rule
            text: {
                // Some CSS
                backgroundColor: grey["80"],
                // color: 'white',
            },
        },
        MuiFormLabel: {
            root: {
                paddingRight: '1em',
            }
        },
        MuiFormGroup: {
            root: {
                alignItems: 'center',
            }
        },
        MuiToolbar:{
            root:{
                minHeight:'48px'
            },
            regular:{
                minHeight:'48px'
            }

        },
        MuiCardHeader: {
            root: {
                padding: '0.3em',
            },
            title: {
                textAlign: 'center',
                fontSize: ' 1.1rem',
                padding: '0.3em',
                // fontFamily:'Copse, serif'
            },
            action: {
                marginTop: '-3px'
            }
        },
    }
}
const controlThemeOptions:ThemeOptions={
    ...defaultThemeOptions,
    overrides: {
    MuiButtonGroup: {
        root: {
            overflow: 'hidden'
        },
    },
    MuiButton: {
        root: {
            borderRadius: 0,
                color: 'yellow'
        },

        // Name of the rule
        text: {
            // Some CSS
            backgroundColor: grey["800"],
                color: 'white',
        },
    },
},
    props: {
        // Name of the component ⚛️
        MuiButtonGroup: {

            // The default props to change
            // @ts-ignore
            disableElevation: true,
        },
        MuiButton: {
            // The default props to change
            disableElevation: true,
        },
    },
};
export const theme = createMuiTheme(defaultThemeOptions);
export const controlTheme = createMuiTheme(controlThemeOptions);