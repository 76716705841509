// import things here
import React, { Component } from "react";
import { connect } from "react-redux";
import { getTime } from "../TrackBufferRecord";
import { Loop } from "../container/Player/LoopRecord";
import { msLoopSelector, timeTrackSelector } from "../_store/selector";
import { getBarPosFromMs } from "./timeUtils";
import { BarPos } from "../_reducers";

export interface InjectedSongPositionProps {
  songPosition: number;
}

export interface Props {
  _audioContext: AudioContext;
  _playing: boolean;
  _startedAt: number;
  _pausedAt: number;
  _looping: boolean;
  _loop: Loop;
  fps: number;

  [key: string]: any;
}

interface State {
  songPosition: number;
  barPosition: BarPos;
}
const getSongPosition = (props) =>
  getTime(
    props._audioContext.currentTime,
    props._playing,
    props._startedAt,
    props._pausedAt,
    props._looping,
    props._loop
  );
// export const withSongPosition = <P extends InjectedSongPositionProps>(
export const withSongPosition = (ChildComponent) => {
  class HOComponent extends Component<Props, State> {
    timer;

    static defaultProps = {
      fps: 20,
    };

    constructor(props) {
      super(props);
      const songPosition = getSongPosition(props);
      this.state = {
        songPosition,
        barPosition: getBarPosFromMs(songPosition * 1000, props.timeTrack),
      };
    }

    handleSetSongPosition = (props: Props) => {
      const songPosition = getSongPosition(props);
      this.setState({
        songPosition,
        barPosition: getBarPosFromMs(songPosition * 1000, props.timeTrack),
      });
    };
    tick = () => {
      this.props._playing && this.handleSetSongPosition(this.props);
    };

    componentDidMount() {
      this.timer = setInterval(
        () => requestAnimationFrame(this.tick),
        1000 / this.props.fps
      );
    }

    componentWillUnmount(): void {
      clearInterval(this.timer);
    }

    render() {
      const {
        _audioContext,
        _playing,
        _startedAd,
        _pausedAt,
        _looping,
        _loop,
        timeTrack,
        ...passThroughProps
      } = this.props;
      return (
        <ChildComponent
          songPosition={this.state.songPosition}
          barPosition={this.state.barPosition}
          timeTrack={timeTrack}
          {...passThroughProps}
        />
      );
    }
  }

  const mapStateToProps = (state) => ({
    _audioContext: state.audioContext,
    _playing: state.playing,
    _startedAt: state.startedAt,
    _pausedAt: state.pausedAt,
    _looping: state.looping,
    timeTrack: timeTrackSelector(state),

    _loop: msLoopSelector(state),
  });

  // HOComponent.displayName = `withSongPosition(${getDisplayName(HOComponent)})`;

  return connect(mapStateToProps, null)(HOComponent);
};
