import React, {useState} from "react";
// import {Button, FormGroup, FormControl, FormControlLabel, InputLabel, TextField} from "@material-ui/core";
import {Button, TextField} from "@material-ui/core";
import {connect} from "react-redux";
import withRoot from "../../withRoot";
import {MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import {AppState, loginAction} from "../../_store";
import {  useHistory } from "react-router-dom";
import {theme} from "../../styles/theme";

const styles = theme => ({
    root: {
        justifyContent: 'center' as 'center',
        textAlign: 'center' as 'center',
        flexGrow: 1,
        display: 'flex',
        margin: theme.spacing(3),
        color: '#555',
    },
    title: {
        backgroundColor: theme.palette.primary,
        color: '#ffffff',
        fontSize: '1.5rem'
    },
    form: {
        textAlign: 'center' as 'center',
        color: '#555',
        display: 'flex',
        flexDirection: 'column' as 'column',
        '& .MuiTextField-root, & .MuiButton-root': {
            margin: theme.spacing(2),
            width: 200,
        },
    }
});

function Login(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const {classes,login} = props;
    const history = useHistory();

    if (props.isAuthenticated){
        history.push("/");
    }
    function validateForm() {
        return username.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        login({username, password})
    }

    return (

        <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
            <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                    id="username"
                    onChange={e => setUsername(e.target.value)}
                    placeholder={'username'}
                />
                <TextField
                    value={password}
                    id="password"
                    placeholder={'password'}
                    type="password"
                    onChange={e => setPassword(e.target.value)}
                />

                <Button variant="contained" color="secondary" disabled={!validateForm()} type="submit">
                    Login
                </Button>
            </form>
        </div>
        </MuiThemeProvider>
    );
}

const mapStateToProps = (state: AppState) => ({
    isAuthenticated:state.isAuthenticated
});

const mapDispatchToProps = (dispatch) => ({
    login: (credentials) => dispatch(loginAction(credentials))
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(withRoot(withStyles(styles)((Login))));
