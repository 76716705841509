import React, {Component, RefObject} from 'react';
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core";
import ReactSlider from '../../components/ReactSlider';
import {fontFamilies} from "../../_shared/constants";

/**
 * To prevent text selection while dragging.
 * http://stackoverflow.com/questions/5429827/how-can-i-prevent-text-element-selection-with-cursor-drag
 */
export function pauseEvent(e) {
    if (e.stopPropagation) {
        e.stopPropagation();
    }
    if (e.preventDefault) {
        e.preventDefault();
    }
    // return false;
}

const styles = ({palette, spacing}: Theme) => createStyles({
    header: {
        textAlign: 'center',
        spacing: 10,
        fontSize: '1.1em'
    },
    sliderList: {
        display: 'flex',
        // touchAction: 'none',
        flexDirection: 'column',
        padding: spacing(1),
        justifyContent: 'center',
        alignItems: 'center'
    },
    track: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '40px',
        margin: '3px'
    },
    channelControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '3px',
        minWidth: '200px',
    },
    slider: {
        border: '1px solid #ddd',
        borderRadius: '3px',
        overflow: 'hidden',
        background: '#ddd',
        boxShadow: '1px 1px 1px inset grey',
        height: '42px',
        width: '200px',
    },
    channel: {
        display: 'flex',
    },
    sliderThumb: {
        display: 'flex',
        borderRadius: '2px',
        // fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#eef',
        backgroundColor: palette.grey.A700,
        opacity: 0.7,
        margin: '0',
        height: '40px',
        width: '42px',
    },
    sliderThumbValue: {
        cursor: 'pointer',
        fontSize: '18px',
        fontFamily: fontFamilies.monospace,
    },
    sliderTrack: {
        // top: '0',
        // bottom: '0',
        // background: 'red',

        padding: '2px',
        height: '42px',
    }
});

interface Props extends WithStyles<typeof styles> {
    onChange: (value: number) => void
    value: number,
    level: number
}

interface State {
    focus: boolean,
    addRemove: number,
}

const trackStyle = {
    position: 'absolute' as 'absolute',
    overflow: 'hidden',
    borderRadius: '2px',
    padding: '2px',
    height: '40px',
    width: 'calc(100% - 4px)',
    top: '0px',
    bottom: 0,
    backgroundColor: 'red',

    backgroundImage: "linear-gradient( to right, green , rgb(120,255,0), rgb(255, 255, 0),#f06d06,red)"
}
const meterStyle = (props: any) => ({
    top: 0,
    bottom: 0,
    right: 0,
    width: `${dbToWidth(props.level)}%`,
    position: 'absolute' as 'absolute',
    backgroundColor: '#fff',
})

function StyledTrack(props) {
    return <>
        <div style={trackStyle}/>
        <div style={meterStyle(props)}/>
    </>
}

const MeterTrack = (props, state) => <StyledTrack {...props} index={state.index}/>;

const dbToWidth = (db: number): number => {
    const maxDb=0
    if (db >= maxDb) {
        return 0;
    }
    const refOffset=maxDb-db;
    const min=50
    if (refOffset > min) {
        return 100
    }
        return Math.log1p(refOffset) * 100 / Math.log1p(min)
}

class Fader extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {focus: false, addRemove: 0};
    }

    componentRef: RefObject<HTMLDivElement> = React.createRef();

    handleTouchStart = (e) => {
        if (this.componentRef.current.contains(e.target)) {
            if (!this.state.focus) {
                console.log('focusing');
                this.setState({focus: true});
                document.addEventListener('touchstart', pauseEvent, {passive: false});
            }
        } else {
            if (this.state.focus) {
                console.log('defocusing');
                this.setState({focus: false});
                document.removeEventListener('touchstart', pauseEvent);
            }
        }
    };

    componentDidMount() {
        if (this.componentRef.current) {
            document.addEventListener('touchstart', this.handleTouchStart, {passive: false});
        }
    }

    componentWillUnmount() {
        if (this.componentRef.current) {
            document.removeEventListener('touchstart', this.handleTouchStart);
            document.removeEventListener('touchstart', pauseEvent);
        }
    }

    render() {
        const {classes, onChange, value, level} = this.props;
        return <div ref={this.componentRef}>
            <ReactSlider
                className={classes.slider}
                thumbClassName={classes.sliderThumb}
                value={value}
                renderTrack={(props, state) => <MeterTrack {...props} level={level}/>}
                renderThumb={(props, state) => <div {...props} >
                    <div className={classes.sliderThumbValue}><span>{state.valueNow}</span></div>
                </div>}
                onChange={onChange}
            />
        </div>
    }
}

export default withStyles(styles)(Fader);