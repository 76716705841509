import React, { useEffect, useRef, useState } from "react";
import { IMarker } from "../Player/MarkerRecord";
import { List } from "immutable";
import { Loop, TimeLoop } from "container/Player/LoopRecord";
import { GridMode } from "../../_store";
import { TimeTrackData } from "../../_reducers";
import {
  canvasPositionToSongPosition,
  songPositionToCanvasPosition,
} from "./SongView";
import { getBarPosFromMs, nearestBar } from "../../_shared/timeUtils";

interface Props {
  marker: List<IMarker>;
  looping: boolean;
  loop: TimeLoop;
  onChangeLoop: (l: Loop) => void;
  onPositionClick: (e) => void;
  gridMode: GridMode;
  duration: number;
  height: number;
  width: number;
  style?: Object;
  timeTrack: Array<TimeTrackData>;
}

const initDragStyle = { cursor: "pointer" };

function getEdgesFromLoop({
  duration,
  width,
  loop,
}: Partial<Props>): { fromX: number; toX: number } {
  const { from, to } = loop;
  const fromX = songPositionToCanvasPosition({
    songPosition: from,
    width,
    duration,
  });
  const toX = songPositionToCanvasPosition({
    songPosition: to,
    width,
    duration,
  });
  return { fromX, toX };
}

function getLoopFromEdges(
  { fromX, toX },
  { duration, width, loop, timeTrack, gridMode }
): Loop {
  const from = canvasPositionToSongPosition({
    x: Math.min(fromX, toX),
    width,
    duration,
  });
  const to = canvasPositionToSongPosition({
    x: Math.max(fromX, toX),
    width,
    duration,
  });

  if (gridMode === "bar" && timeTrack.length) {
    const fromPos = getBarPosFromMs(from * 1000, timeTrack);
    const toPos = getBarPosFromMs(to * 1000, timeTrack);
    const fromBar = nearestBar(fromPos, timeTrack);
    const toBar = nearestBar(toPos, timeTrack);
    return {
      ...loop,
      type: "bar",
      from: fromBar,
      to: toBar,
    };
  }

  return {
    ...loop,
    type: "ms",
    from,
    to,
  };
}

function MarkerCanvasView(props: Props) {
  const {
    width,
    duration,
    loop,
    looping,
    height,
    onChangeLoop,
    onPositionClick,
  } = props;
  const canvasRef = useRef(null);
  const [loopEdges, setLoopEdges]: [
    { fromX: number; toX: number },
    any
  ] = useState(getEdgesFromLoop(props));
  const [isDragging, setIsDragging] = useState(null);
  const [dragStyle, setDragStyle] = useState(initDragStyle);
  const { fromX, toX } = loopEdges;

  function getNearObjects(e, canvasRef, props: Props) {
    const { x, y } = getMousePos(e, canvasRef.current);
    const from = Math.abs(fromX - x) < 20;
    const to = Math.abs(toX - x) < 20;
    const area = fromX < x && x < toX;
    const near = {
      from: from,
      to: to,
      area: !from && !to && area,
    };
    return { x, y, near };
  }

  function getDragTarget(e, canvasRef, props: Props) {
    const { near, x } = getNearObjects(e, canvasRef, props);
    if (near.from || near.to) {
      setDragStyle({ cursor: "col-resize" });
      if (near.from) {
        return {
          time: performance.now(),
          key: "fromX",
          x,
        };
      }
      return {
        time: performance.now(),
        key: "toX",
        x,
      };
    }
    if (near.area) {
      setDragStyle({ cursor: "move" });
      return {
        time: performance.now(),
        key: "area",
        x,
        fromX,
        toX,
      };
    }
    return null;
  }

  function onMouseMove(e, canvasRef, props: Props) {
    const { near } = getNearObjects(e, canvasRef, props);
    if (near.from || near.to) {
      setDragStyle({ cursor: "col-resize" });
    } else if (near.area) {
      setDragStyle({ cursor: "move" });
    } else {
      setDragStyle(initDragStyle);
    }
  }

  function onDragEnd(e, canvasRef, props: Props) {
    setDragStyle(initDragStyle);
    setIsDragging(null);
    if (isDragging && performance.now() - isDragging.time > 100) {
      onChangeLoop(getLoopFromEdges(loopEdges, props));
    } else {
      onPositionClick(e);
      onResetDrag();
    }
  }

  function onDragStart(e, canvasRef, props: Props) {
    // if (!looping) return;
    setIsDragging(getDragTarget(e, canvasRef, props));
  }

  function onDragUpdate(e, canvasRef, props: Props) {
    const { x } = getMousePos(e, canvasRef.current);
    if (isDragging.key !== "area") {
      setLoopEdges({ ...loopEdges, [isDragging.key]: x });
    } else {
      setLoopEdges({
        ...loopEdges,
        fromX: x - isDragging.x + isDragging.fromX,
        toX: x - isDragging.x + isDragging.toX,
      });
    }
  }

  function onResetDrag() {
    setIsDragging(null);
    setLoopEdges(getEdgesFromLoop(props));
  }

  useEffect(() => {
    setLoopEdges(getEdgesFromLoop({ duration, width, loop }));
  }, [duration, width, loop]);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, width, height);
    drawLoop(ctx, loopEdges, looping);
  }, [looping, loopEdges, height, width]);
  return (
    <div style={{ position: "absolute", left: 0, top: 0, zIndex: 100 }}>
      <canvas
        width={width}
        height={height}
        onMouseLeave={onResetDrag}
        onMouseDown={(e) => {
          onDragStart(e, canvasRef, props);
        }}
        onMouseUp={(e) => {
          onDragEnd(e, canvasRef, props);
        }}
        onMouseMove={(e) => {
          isDragging
            ? onDragUpdate(e, canvasRef, props)
            : onMouseMove(e, canvasRef, props);
        }}
        style={{
          ...dragStyle,
          backgroundColor: "rgba(255,255,255,0.4)",
          position: "absolute",
          // pointerEvents: "none",
          top: 0,
          left: 0,
          height: `${height}px`,
          width: `${width}px`,
          zIndex: 5,
        }}
        ref={canvasRef}
      />
    </div>
  );
}

function drawLoop(
  ctx: CanvasRenderingContext2D,
  { fromX, toX },
  looping: boolean
) {
  if (looping) {
    ctx.fillStyle = "rgba(242,99,137,0.3)";
    ctx.strokeStyle = "rgba(242,99,137,0.9)";
  } else {
    ctx.fillStyle = "rgba(242,200,230,0.1)";
    ctx.strokeStyle = "rgba(100,100,100,0.6)";
  }
  const start = fromX;
  ctx.fillRect(start, 0, toX - fromX, ctx.canvas.height);
  ctx.strokeRect(start, 0, toX - fromX, ctx.canvas.height);
}

function getMousePos(evt, canvas) {
  let rect = canvas.getBoundingClientRect();
  return {
    x: evt.clientX - rect.left,
    y: evt.clientY - rect.top,
  };
}

export default MarkerCanvasView;
