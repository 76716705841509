import React from 'react';
import {FormControlLabel, FormGroup, FormLabel, Switch} from "@material-ui/core";

interface SwitchData{ id: string, active: boolean, label?: string }

interface VProps {
    data:SwitchData,
    onChange: (boolean) => void,
}

export function StateSwitch({data, onChange}:VProps) {
    return  <FormControlLabel
                      control={<Switch checked={data.active}
                                       onChange={({target: {name, checked}}) => onChange( checked)}
                                       name={data.id}/>}
                      label={data.label||''}
    />
}

interface Props {
    switchStates: Array<SwitchData>,
    onChange: ({id: string, active: boolean}) => void,
    children?:any
}
function StateSwitches({switchStates, onChange,children}:Props) {
    return <FormGroup row>
        {children &&<FormLabel>{children?children:null}</FormLabel>}
        {switchStates.map((data) =><StateSwitch key={data.id}
        onChange={(active)=>onChange({...data,active})} data={data}
        />)}
    </FormGroup>
}

export default StateSwitches;