import {
  markerConstants,
  noteConstants,
  playerConstants,
  playlistConstants,
  songConstants,
  userConstants,
} from "../_shared/constants";
import { List, Map } from "immutable";
import AudioBufferLoader, { ISongData } from "../AudioBufferLoader";
import { IMarker } from "../container/Player/MarkerRecord";
import { AppState, IMixerChannel, LoopingState } from "../_store";
import { uuidv4 } from "../_shared/utils";
import MixerChannel from "../MixerChannel";
import { getAudioChannels } from "../_shared/audioUtils";

const audioContext: AudioContext = new ((window as any).AudioContext ||
  (window as any).webkitAudioContext)();
const bufferLoader = new AudioBufferLoader(audioContext);
const master = new MixerChannel(audioContext);

export const initialState: AppState = {
  audioContext,
  gridMode: "ms",
  bufferLoader,
  songs: Map(),
  channels: { master },
  playFrom: null,
  looping: 0,
  sequence: [],
  playing: false,
  playlist: null,
  playlists: null,
  loading: null,
  trackBuffer: Map(),
  marker: Map(),
  loops: Map(),
  loop: {
    id: uuidv4(),
    label: "loop",
    from: [1, 0, 0, 0],
    to: [5, 0, 0, 0],
    type: "bar",
  },
  startedAt: 0,
  pausedAt: 0,
  soundSources: [],
  notes: [],
  isAuthenticated: false,
  isAuthenticating: false,
  user: undefined,
  groups: [],
};

/**
 *  barNr, ms, tempo (default by next point), beats per measure (default 4), measure (default 4)
 */
export type TimeTrackData = Array<number>;
/**
 * bar, measure, 16th,fraction (and beatcount)
 *
 * all values but bar are positive only and start by 1, measure is context sensitive an always a multiple of the 16th
 *
 */
export type BarPos = [number, number, number, number];

interface MixerChannels {
  master: IMixerChannel;

  [key: string]: IMixerChannel;
}

function getChannels(song: ISongData, state: AppState): MixerChannels {
  let channels = { master: state.channels.master };
  for (let track of song.tracks) {
    channels[track.id] = new MixerChannel(state.audioContext, {
      level: track.defaultLevel,
      type: "audio",
      id: track.id,
      name: track.name,
      destination: master,
    });
  }
  return channels;
}

export function rootReducer(
  state = initialState,
  action: { type: string; [key: string]: any }
): AppState {
  switch (action.type) {
    case noteConstants.FETCH_SUCCESS: {
      const { notes } = action;
      return {
        ...state,
        notes,
      };
    }
    case playlistConstants.GET_ALL_SUCCESS: {
      const { playlists } = action;
      return {
        ...state,
        playlists,
      };
    }
    case playlistConstants.FETCH_SUCCESS: {
      const { playlist } = action;
      const songs: Map<string, ISongData> = Map(
        playlist.songs.map((source) => [source.id, source])
      );
      const marker: Map<string, List<IMarker>> = Map(
        playlist.songs.map((source) => [source.id, List(source.marker_set)])
      );
      return {
        ...state,
        playlist,
        songs,
        marker,
      };
    }
    case markerConstants.ADD_REQUEST: {
      const { songId, marker: newMarker } = action;
      const { marker: oldMarker } = state;
      const marker = oldMarker.update(songId, (marker) =>
        (marker.push(newMarker as any) as any).sort((a, b) => a.time - b.time)
      );
      return {
        ...state,
        marker,
      };
    }
    case markerConstants.UPDATE_REQUEST: {
      const { songId, marker: updatedMarker } = action;
      const { marker: oldMarker } = state;
      const marker = oldMarker.update(songId, (marker) => {
        const index = marker.findIndex(({ id }) => id === updatedMarker.id);
        return marker.set(index, updatedMarker);
      });
      return {
        ...state,
        marker,
      };
    }
    case markerConstants.DELETE_REQUEST: {
      const { songId, markerId } = action;
      const { marker: oldMarker } = state;
      const marker = oldMarker.update(songId, (marker) => {
        const index = marker.findIndex(({ id }) => id === markerId);
        return marker.remove(index);
      });
      return {
        ...state,
        marker,
      };
    }
    case songConstants.UPDATE: {
      const { song } = action.payload;
      const songs = state.songs.set(song.id, song);
      return {
        ...state,
        songs,
      };
    }
    case playerConstants.RESET_PLAY_FROM: {
      return {
        ...state,
        playFrom: null,
      };
    }
    case playerConstants.PLAY_FROM: {
      const { time } = action.payload;
      return {
        ...state,
        playFrom: time,
        playing: true,
      };
    }
    case playerConstants.PLAY_FROM_SUCCESS: {
      const startedAt = action.payload;
      return {
        ...state,
        playFrom: null,
        startedAt,
      };
    }
    case playlistConstants.SELECT_SONG:
      return {
        ...state,
        songId: action.payload.id,
      };
    case playerConstants.STOP_SONG: {
      return {
        ...state,
        pausedAt: state.audioContext.currentTime - state.startedAt,
        playing: false,
      };
    }
    case playerConstants.START_SONG:
      const { id, time } = action.payload;
      const newSong = id !== state.songId;
      if (newSong) {
        for (let ch of getAudioChannels(state)) {
          ch.stopAudio();
        }
      }
      return {
        ...state,
        songId: id,
        playFrom: time,
        gridMode:
          id !== state.songId
            ? state.songs.get(id).meta.timeTrack.length
              ? "bar"
              : "ms"
            : state.gridMode,
        playing: true,
        channels: newSong
          ? getChannels(state.songs.get(id), state)
          : state.channels,
      };
    case songConstants.LOAD_REQUEST: {
      const { songId } = action;
      return {
        ...state,
        loading: { songId, completed: [0, 0] },
      };
    }
    case songConstants.LOAD_PROGRESS: {
      const { songId, progress } = action.payload;
      return {
        ...state,
        loading: { songId, completed: progress },
      };
    }
    case songConstants.LOAD_FAILURE:
      const { error } = action;
      return {
        ...state,
        loading: null,
        loadingError: error,
      };
    case songConstants.LOAD_SUCCESS:
      const { song } = action;
      for (let ch of getAudioChannels(state)) {
        ch.stopAudio();
      }
      return {
        ...state,
        loading: null,
        playing: true,
        songId: song.id,
        gridMode: song.meta.timeTrack.length ? "bar" : "ms",
        songs: state.songs.set(song.id, song),
        channels: getChannels(song, state),
      };
    case playerConstants.CHANGE_LOOP:
      const { loop } = action;
      // if (loop.from > loop.to) {
      //   const tmp = loop.from;
      //   loop.from = loop.to;
      //   loop.to = tmp;
      // }
      return {
        ...state,
        loop,
      };

    case playerConstants.CHANGE_LOOPING: {
      let { looping } = action;
      if (looping === undefined) {
        if (state.looping === LoopingState.off) {
          looping = LoopingState.single;
        } else {
          looping = LoopingState.off;
        }
      }
      return {
        ...state,
        looping,
      };
    }
    case playerConstants.CHANGE_GRIDMODE:
      const { gridMode } = action;
      return {
        ...state,
        gridMode,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        authToken: action.auth_token,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
        authToken: null,
      };
    case userConstants.SELECT_GROUP:
      return {
        ...state,
        group: action.group,
      };
    case userConstants.FETCH_SUCCESS:
      const { user } = action;
      return {
        ...state,
        user,
        group: user.voice_group,
        // @ts-ignore
        groups: user.available_groups,
        authToken: null,
      };
    default:
      return state;
  }
}
