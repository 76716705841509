import { Record } from "immutable";
import { uuidv4 } from "./_shared/utils";
import { TimeLoop } from "./container/Player/LoopRecord";

export interface TrackBuffer {
  buffer: null | AudioBuffer;
  from?: number;
  to?: number;
  name: string;
  id: string;
}

export type PartialTrack = Partial<TrackBuffer>;

export interface ITrackBuffer extends TrackBuffer {
  set<K extends keyof TrackBuffer>(key: K, value: TrackBuffer[K]): ITrackBuffer;

  getIn(keyPath: ["contents", string]): TrackBuffer | undefined;

  setIn(keyPath: ["contents", string], value: TrackBuffer): ITrackBuffer;

  deleteIn(keyPath: ["contents", string]): ITrackBuffer;

  withMutations(mutator: (s: ITrackBuffer) => any): ITrackBuffer;

  // Merge is made easy using typescript's new mapped types!!!
  merge(partial: PartialTrack): ITrackBuffer;
}

const TrackBufferRecord = Record({
  buffer: null,
  name: "Songname",
  id: uuidv4(),
});

/**
 * gets the songtime,
 * if playing: it is the absolute Context Time minus the absolute ms when the song was started.
 * startedAt can be negative, e.g. if the song starts in the middle right after creation of the absolute timer
 * @param {number} currentTime
 * @param {boolean} playing
 * @param {number} startedAt
 * @param {number} pausedAt
 * @param looping
 * @param loop
 * @returns {number}
 */
export const getTime = (
  currentTime: number,
  playing: boolean,
  startedAt: number,
  pausedAt: number,
  looping: boolean,
  loop: TimeLoop
): number => {
  if (!playing) {
    return pausedAt;
  }
  const absPos = currentTime - startedAt;
  if (!looping || absPos < loop.from) {
    return absPos;
  } else {
    const loopDuration = loop.to - loop.from;
    return ((absPos - loop.from) % loopDuration) + loop.from;
  }
};

export const getDuration = (track: ITrackBuffer) => track.buffer.duration;

export default TrackBufferRecord;
