import React, {
  useCallback,
  useEffect,
  // useState,
  // useCallback
} from "react";
import { IMarker, Marker } from "./MarkerRecord";
import { List as IMList } from "immutable";
import { ISongData } from "../../AudioBufferLoader";
import { Button, ButtonGroup } from "@material-ui/core";
import {
  makeStyles,
  // makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { GridMode } from "../../_store";

import SongPosition from "./SongPosition";
import SongButton from "./SongButton";
import LoopPlayControls from "./LoopPlayControls";
import { SkipPrevious, FastForward, FastRewind } from "@material-ui/icons";
import { controlTheme } from "../../styles/theme";
// import MarkerList from "./MarkerList";
import { withSongPosition } from "../../_shared/withSongPosition";
import { getMsFromBarPos } from "../../_shared/timeUtils";
import { BarPos, TimeTrackData } from "../../_reducers";
import useKeyPress from "../../hooks/useKeyPress";
import { uuidv4 } from "../../_shared/utils";

const useStyles = makeStyles(({ mixins, spacing }) => ({
  paper: mixins.gutters({
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    color: "#222",
  }),
  button: {},
  input: {
    display: "none",
  },
}));

type Props = {
  id: string;
  toggle: Function;
  onAddMarker: (songId: string, marker: Marker) => void;
  onUpdateMarker: (songId: string, marker: IMarker) => void;
  onDeleteMarker: (songId: string, marker: IMarker) => void;
  song: ISongData;
  songPosition: number;
  barPosition: BarPos;
  onChangeGridMode: (mode: GridMode) => void;
  gridMode: GridMode;
  startedAt: number;
  timeTrack: Array<TimeTrackData>;
  marker: IMList<IMarker>;
  audioCtx: AudioContext;
  playing: boolean;
  onChangeLooping: () => void;
  onPlayFrom: (time: any) => void;
};

/**
 *
 * @param id
 * @param toggle
 * @param addMarker
 * @param song
 * @param playing
 * @param marker
 * @returns {*}
 * @constructor
 */
function PlayControls({
  id,
  toggle,
  onPlayFrom,
  songPosition,
  onAddMarker,
  onChangeLooping,
  barPosition,
  timeTrack,
  gridMode,
  onChangeGridMode,
  // onDeleteMarker,
  // onUpdateMarker,
  song,
  // startedAt,
  playing,
  marker,
}: Props) {
  const classes = useStyles();
  // const playPress = useKeyPress(" ");
  const loopPress = useKeyPress("l");
  // const [state, setState] = useState();

  // const eventListener = useCallback(() => {
  //     console.log(state); // use the stateful variable in event listener
  // }, [state]);
  useEffect(() => {
    //   if (playPress) {
    //     toggle(song);
    //   }
    if (loopPress) {
      onChangeLooping();
    }
  }, [
    // playPress,
    loopPress,
    onChangeLooping,
    song,
    toggle,
  ]);

  function skipForward() {
    if (gridMode === "ms") {
      onPlayFrom(Math.max(0, songPosition + 5));
    } else {
      const skipBarPosAsS =
        getMsFromBarPos([barPosition[0] + 1, 1, 1, 0], timeTrack) / 1000;
      onPlayFrom(Math.max(0, skipBarPosAsS));
    }
  }

  function skipBack() {
    if (gridMode === "ms") {
      onPlayFrom(songPosition - 5);
    } else {
      const skipBarPosAsS =
        getMsFromBarPos([barPosition[0], 1, 1, 0], timeTrack) / 1000;
      onPlayFrom(skipBarPosAsS);
    }
  }
  const handleAddMarker = useCallback(() => {
    if (gridMode === "ms") {
      onAddMarker(id, {
        id: uuidv4(),
        pos: songPosition * 1000,
        label: "",
        type: "ms",
      });
    }
    if (gridMode === "bar") {
      onAddMarker(id, {
        id: uuidv4(),
        pos: barPosition,
        label: "",
        type: "bar",
      });
    }
  }, [songPosition, barPosition, gridMode, id, onAddMarker]);

  return (
    <ThemeProvider theme={controlTheme}>
      <div>
        <ButtonGroup>
          <Button
            variant="contained"
            onClick={() => {
              onPlayFrom(0);
            }}
          >
            <SkipPrevious />
          </Button>
          <Button variant="contained" onClick={skipBack}>
            <FastRewind />
          </Button>
          <SongButton
            song={song}
            small
            active={true}
            playing={playing}
            onClick={() => toggle(song)}
            id={id}
          />
          <LoopPlayControls />
          <Button variant="contained" onClick={skipForward}>
            <FastForward />
          </Button>
          <SongPosition
            duration={song.duration}
            onSetPos={onPlayFrom}
            songPosition={songPosition}
            gridMode={gridMode}
            onChangeGridMode={onChangeGridMode}
            timeTrack={song.meta.timeTrack}
          />
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleAddMarker}
          >
            Add Marker
          </Button>
        </ButtonGroup>
      </div>
      {/*{<div><Card> {secondsToString(getTime(track, audioCtx))} | {secondsToString(getDuration(track))}</Card></div>}*/}
    </ThemeProvider>
  );
}

export default withSongPosition(PlayControls);
