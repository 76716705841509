import { Map } from "immutable";
import TrackBufferRecord, { ITrackBuffer } from "./TrackBufferRecord";
require("promise-decode-audio-data");

export interface ITrackData {
  id: string;
  url: string;
  name: string;
  defaultLevel: number;
}
/**
 *  ms measure, (staff),
 */
export type TimeTrackData = [number, number, number?, number?];

export interface ISongMetaData {
  timeTrack: Array<TimeTrackData>;
}
export interface ISongData {
  tracks: Array<ITrackData>;
  meta: ISongMetaData;
  sheets: Array<{ file: string; name: string }>;
  id: string;
  loaded?: boolean;
  duration?: number;
  name: string;
}

export default class AudioBufferLoader {
  context: AudioContext;
  bufferMap: Map<string, Map<string, ITrackBuffer>>;

  constructor(context) {
    this.context = context;
    this.bufferMap = Map();
  }

  /**
   * loading all trackChannels for a song and putting them into the buffer map
   * @param {ISongData} song
   * @param progressHandler
   * @returns {Promise<{ song: ISongData, error: any }>}
   */
  async loadSong(
    song: ISongData,
    progressHandler: (data: { songId: string; progress: Array<number> }) => void
  ): Promise<{ song: ISongData; error: any }> {
    // try {
    const { tracks, id: songId } = song;
    let trackFetches = [];
    const trackCount = tracks.length;
    let trackData: Map<string, any> = Map();
    for (let track of tracks) {
      trackFetches.push(fetch(track.url));
    }

    let loadProgress = 0;
    let decodeProgress = 0;

    const completePartialProgress = 100 / trackCount;
    let trackBuffers = [];
    for (let trackFetchIndex in trackFetches) {
      const response = await trackFetches[trackFetchIndex];
      loadProgress += completePartialProgress;
      progressHandler({ songId, progress: [loadProgress, 0] });
      //TODO CHECK HIT ON PERFORMANCE BY PROGRESS HANDLING BYTECOUNT
      // const reader=response.body.getReader();
      //
      //
      // const currentProgress=((100/(trackFetches.length))*(parseInt(trackFetchIndex)));
      // const completePartialProgress=(100/(trackFetches.length));
      // const contentLength = response.headers.get('content-length');
      // const total = parseInt(contentLength, 10);
      // console.log('total',total);
      // let loaded=0;
      // const stream = new ReadableStream({
      //
      //     start(controller) {
      //         // Die folgende Funktion behandelt jeden Daten-Chunk
      //         function read() {
      //             reader.read().then(({done, value}) => {
      //                 if (done) {
      //                     controller.close();
      //                     return;
      //                 }
      //                 loaded += value.byteLength;
      //                 // console.log('loaded',loaded);
      //                 const partialProgressPercent=progress({loaded,total});
      //                 const progressPercentage=currentProgress+completePartialProgress*partialProgressPercent;
      //                 // console.log('progressPercentage',progressPercentage);
      //                 progressHandler(song.id,progressPercentage);
      //                 controller.enqueue(value);
      //             }).then(read)
      //                 .catch(error => {
      //                 console.error(error);
      //             })
      //         }
      //         read();
      //     }
      // });
      // tracks.push(new Response(stream, { headers: { "Content-Type": "text/html" } }))

      trackBuffers.push(response);
    }
    for (let index in trackBuffers) {
      const response = trackBuffers[index];
      const { name, id } = tracks[index];
      const arrayBuffer = await response.arrayBuffer();
      const trackBuffer = await this.context.decodeAudioData(arrayBuffer);
      decodeProgress += completePartialProgress;
      progressHandler({
        songId: song.id,
        progress: [loadProgress, decodeProgress],
      });
      const trackDataId = `track_${id}`;
      trackData = trackData.set(
        trackDataId,
        new TrackBufferRecord({
          buffer: trackBuffer,
          id: trackDataId,
          name,
        })
      );
    }
    this.bufferMap = this.bufferMap.set(songId, trackData);

    return {
      song: {
        ...song,
        loaded: true,
        duration: Math.max(
          ...trackData
            .valueSeq()
            .toArray()
            .map(({ buffer }) => buffer.duration || 0)
        ),
      },
      error: null,
    };
    // } catch (error) {
    //     // Error :(
    //     console.log(error);
    //     return {song: null, error};
    // }
  }

  //
  // async loadAll(songs) {
  //     return Promise.all(
  //         songs.map(song => {
  //             return this.loadSong(song)
  //         })).then((buffer) => {
  //         return buffer
  //     });
  // }

  getSongBy(id): Map<string, ITrackBuffer> | null {
    return this.bufferMap.get(id);
  }
}

// const  progress=({loaded, total})=> loaded/total;
