import {call, put, takeLatest} from 'redux-saga/effects'
import * as api from '../_shared/api';
import {userConstants} from "../_shared/constants";

// worker Saga: will be fired on PLAYLIST_FETCH_REQUESTED actions
function* currentUserRequested(action) {
    try {
        const result = yield call(() => api.fetchCurrentUser());
        yield put({type: userConstants.FETCH_SUCCESS, user: result});
    } catch ({message}) {
        yield put({type: userConstants.FETCH_FAILURE});
    }
}


function* currentUserSaga() {
    yield takeLatest(userConstants.FETCH_REQUEST, currentUserRequested);
}

export default currentUserSaga;