import React from 'react';
import {ThemeProvider, withStyles} from '@material-ui/core/styles';
import withRoot from './withRoot';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/es/Toolbar/Toolbar";
import {Typography} from "@material-ui/core/es/index";
import SongPlayer from "./container/Player/SongPlayer";
import Routes from "./router/Routes";
import {connect} from "react-redux";
import {
    AppState, logoutAction,

} from "./_store";
import UserHeader from "./UserHeader";
import {theme} from "./styles/theme";


const styles = ({palette}) => ({
    root: {
        flexGrow: 1,
    },
    mainTitle: {
        color: '#ffffff',
        fontWeight: 300,
        fontSize: '1.1em'
    },
    title: {
        fontSize: '1.1rem'
    },
    toolbar: {
        color: '#ffffff',
        fontSize: '1.2rem',
        display: 'flex',
        justifyContent: 'space-between'
    }
});

interface Props {
    classes: any;
}

const contentStyle = (ref) => {
    // const pad = 1;

    // const left = 0;
    return {
        marginTop: `${ref.current?ref.current.innerHeight:48}px`,
        // marginLeft: left + pad,
        // marginBottom: pad,
        // marginRight: pad,
        padding: '1vw'
    };
};
const betaStyle={
    position:'absolute' as 'absolute',
    top: 0,
    left: '4px',
    transform: 'rotate(-13deg)'
}
function BetaStamp(){
    return <span style={betaStyle}>Beta</span>
}

function App(props:Props) {
    const title = 'Rehearsalizer';
    const {classes} = props;
    const barRef = React.useRef();
    return <ThemeProvider theme={theme}>
        <div className={classes.root}>
            <SongPlayer />
            <AppBar ref={barRef} title={title}>
                <Toolbar variant="dense" className={classes.toolbar}>
                    <Typography variant={"h1"} className={classes.mainTitle}>
                        {title}
                    </Typography>
                    <UserHeader/>
                </Toolbar>
                <BetaStamp/>
            </AppBar>

            {/*<RemoveScroll>*/}
            <div style={contentStyle(barRef)}>
                <Routes appProps={{}} />
            </div>
            {/*</RemoveScroll>*/}
        </div>
    </ThemeProvider>;
}

const mapStateToProps = (state: AppState) => (
    {
        isAuthenticated: state.isAuthenticated,
    }
);

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    },
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(withRoot(withStyles(styles)((App))));
