import React, { Component } from "react";
import { secondsToString } from "../../_shared/utils";
import { songPositionToCanvasPosition } from "./SongView";
import { fontFamilies } from "../../_shared/constants";
import { getBarPosFromMs, getMsFromBarPos } from "../../_shared/timeUtils";
import { BarPos, TimeTrackData } from "../../_reducers";
import { GridMode } from "../../_store";

interface Props {
  width: number;
  height: number;
  style: Object;
  timeTrack: Array<TimeTrackData>;
  duration: number;
  gridHeight: number;
  gridMode: GridMode;
}

interface State {
  canvasCtx: CanvasRenderingContext2D | null;
  trigger?: number;
}

export function adaptTickCount(width: number, ticks: number[]) {
  const maxTickCount = width / 20;
  if (ticks.length > maxTickCount) {
    const tickOvershootFactor = Math.ceil(ticks.length / maxTickCount);
    ticks = ticks.filter(
      (tick, index) =>
        index === 0 ||
        index === ticks.length - 1 ||
        index % tickOvershootFactor === 0
    );
  }
  return ticks;
}

/**
 *
 * @param ctx
 * @param text
 * @param songPosition
 * @param width
 * @param duration
 * @param y
 * @param fontSize
 */
export function drawTick(
  ctx: CanvasRenderingContext2D,
  text: string,
  songPosition: number,
  width: number,
  duration: number,
  y: number,
  fontSize: number
) {
  // ctx.save()
  // if(value===0){
  //     debugger;
  // }
  const xPos = songPositionToCanvasPosition({ songPosition, width, duration });
  ctx.fillStyle = "black";
  ctx.font = `bold ${fontSize}px "${fontFamilies.monospace}" monospace`;
  ctx.textAlign = "left";
  // ctx.textBaseline = 'alphabetic'
  ctx.fillText(text, xPos, y);
  ctx.beginPath();
  ctx.strokeStyle = "rgba(100,100,100,0.3)";
  ctx.moveTo(xPos, 0);
  ctx.lineTo(xPos, ctx.canvas.height);
  ctx.stroke();
  // const textWidth = ctx.measureText(text).width
  // ctx.restore()
}

const gridRange = (maxTime: number) => Math.ceil(maxTime / 15) + 1;

export default class SongGrid extends Component<Props, State> {
  canvas: HTMLCanvasElement;
  state: {
    canvasCtx: null;
    trigger: number;
  };

  componentDidMount() {
    const canvasCtx = this.canvas.getContext("2d");
    this.setState({ canvasCtx });
  }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any
  ): boolean {
    return (
      (!this.state && !!nextState) ||
      nextProps.duration !== this.props.duration ||
      this.props.width !== nextProps.width ||
      this.props.gridMode !== nextProps.gridMode ||
      this.state.trigger !== nextState.trigger
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.width !== prevProps.width) {
      const canvasCtx = this.canvas.getContext("2d");
      this.setState({ canvasCtx, trigger: Math.random() });
    }
  }

  drawBarGrid = (context: CanvasRenderingContext2D) => {
    const { width, height, duration, timeTrack } = this.props;
    const endBar = getBarPosFromMs(duration * 1000, timeTrack);

    if (endBar[0] > 0) {
      let ticks = [...Array(endBar[0]).keys()];
      ticks = adaptTickCount(width, ticks);
      for (let tick of ticks) {
        const measurePos: BarPos = [tick, 1, 1, 0];
        const posMs = getMsFromBarPos(measurePos, timeTrack);
        const text = measureTickLabel(measurePos);
        drawTick(
          this.state.canvasCtx,
          text,
          posMs / 1000,
          width,
          duration,
          height - 2,
          height - 2
        );
      }
    }
  };

  displayGrid = (context: CanvasRenderingContext2D) => {
    const { width, gridMode, height } = this.props;
    context.save();
    context.clearRect(0, 0, width, height);
    if (gridMode === "bar") {
      this.drawBarGrid(context);
    } else {
      this.drawMSGrid(context);
    }
    context.stroke();
    context.restore();
  };

  drawMSGrid = (context: CanvasRenderingContext2D) => {
    const { width, height, duration } = this.props;
    let ticks = [...Array(gridRange(duration)).keys()]
      .map((tick) => tick * 15)
      .concat([duration]);
    ticks = adaptTickCount(width, ticks);
    for (let tick of ticks) {
      const text = secondsToString(tick);
      drawTick(context, text, tick, width, duration, height - 2, height - 2);
    }
  };

  render() {
    const { height, width, duration, style } = this.props;
    if (duration && this.state) {
      console.log("displayGrid");
      this.displayGrid(this.state.canvasCtx);
    }
    return (
      <canvas
        width={width}
        height={height}
        style={{
          position: "absolute",
          left: 0,
          height: `${height}px`,
          width: `${width}px`,
          ...style,
        }}
        ref={(ref) => (this.canvas = ref)}
      />
    );
  }
}

const measureTickLabel = ([bar, measure, beat, fraction]: BarPos) =>
  [bar, measure === 1 ? null : measure, beat === 1 ? null : beat]
    .filter((val) => val !== null)
    .join(":");
