export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const userConstants = {
    FETCH_REQUEST: 'USERS_FETCH_REQUEST',
    FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
    FETCH_FAILURE: 'USERS_FETCH_FAILURE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GET_ALL_REQUEST: 'USERS_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'USERS_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'USERS_GET_ALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    SELECT_GROUP: 'USERS_SELECT_GROUPS'
};

export const markerConstants = {
    ADD_REQUEST: 'MARKER_ADD_REQUEST',
    ADD_SUCCESS: 'MARKER_ADD_SUCCESS',
    ADD_FAILURE: 'MARKER_ADD_FAILURE',
    UPDATE_REQUEST: 'MARKER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'MARKER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'MARKER_UPDATE_FAILURE',
    DELETE_REQUEST: 'MARKER_DELETE_REQUEST',
    DELETE_SUCCESS: 'MARKER_DELETE_SUCCESS',
    DELETE_FAILURE: 'MARKER_DELETE_FAILURE',
};

export const loopConstants = {
    ADD_REQUEST: 'LOOP_ADD_REQUEST',
    ADD_SUCCESS: 'LOOP_ADD_SUCCESS',
    ADD_FAILURE: 'LOOP_ADD_FAILURE',
    UPDATE_REQUEST: 'LOOP_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'LOOP_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'LOOP_UPDATE_FAILURE',
    DELETE_REQUEST: 'LOOP_DELETE_REQUEST',
    DELETE_SUCCESS: 'LOOP_DELETE_SUCCESS',
    DELETE_FAILURE: 'LOOP_DELETE_FAILURE',
};
export const playlistConstants = {

    GET_ALL_REQUEST: 'PLAYLIST_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'PLAYLIST_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'PLAYLIST_GET_ALL_FAILURE',

    FETCH_REQUEST: 'PLAYLIST_FETCH_REQUEST',
    FETCH_SUCCESS: 'PLAYLIST_FETCH_SUCCESS',
    FETCH_FAILURE: 'PLAYLIST_FETCH_FAILURE',
    SELECT_SONG: 'PLAYLIST_SELECT_SONG',
};
export const songConstants = {

    LOAD_REQUEST: 'SONG_LOAD_REQUEST',
    LOAD_SUCCESS: 'SONG_LOAD_SUCCESS',
    LOAD_PROGRESS: 'SONG_LOAD_PROGRESS',
    LOAD_FAILURE: 'SONG_LOAD_FAILURE',
    SELECT_SONG: 'SONG_SELECT_SONG',
    UPDATE: 'SONG_UPDATE',
};
export const noteConstants = {
    FETCH_REQUEST: 'NOTES_FETCH_REQUEST',
    FETCH_SUCCESS: 'NOTES_FETCH_SUCCESS',
    FETCH_FAILURE: 'NOTES_FETCH_FAILURE',
};

export const playerConstants = {
    INIT: 'PLAYER_INIT',
    PLAY_FROM: 'PLAY_FROM',
    PLAY_FROM_SUCCESS: 'PLAY_FROM_SUCCESS',
    PLAYER_SET_ELAPSED: 'SET_ELAPSED',
    RESET_PLAY_FROM: 'RESET_PLAY_FROM',
    UPDATE_CHANNEL: 'PLAYER_UPDATE_CHANNEL',
    UPDATE_CHANNELS: 'PLAYER_UPDATE_CHANNELS',
    STOP_SONG: 'STOP_SONG',
    CHANGE_LOOPING: 'CHANGE_LOOPING',
    CHANGE_LOOP: 'CHANGE_LOOP',
    CHANGE_GRIDMODE: 'CHANGE_GRIDMODE',
    START_SONG: 'START_SONG',

};

export const fontFamilies = {
    monospace: 'Inconsolata'
}