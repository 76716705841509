import React, {PureComponent} from 'react';
import Grid from "@material-ui/core/es/Grid/Grid";
import {withStyles, Theme, createStyles, WithStyles, CardContent, Card, CardHeader, Divider} from "@material-ui/core";
import {connect} from "react-redux";
import {
    AppState,
    Playlist
} from "../../_store";
import { Map} from "immutable";
import SongButton from "./SongButton";
import {ISongData} from "../../AudioBufferLoader";


const styles = ({palette, spacing}: Theme) => createStyles({
    leftIcon: {
        marginRight: spacing(1),
    },
    rightIcon: {
        marginLeft: spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    }
});

const stylesInjector = withStyles(styles);

interface Props extends WithStyles<typeof styles> {
    songId: string,
    onToggle: (id: string) => void,
    playlist: Playlist | null,
    songs: Map<string, ISongData>,
    playing: boolean,
}

const DecoratedAudioContainer = stylesInjector(
    class Playlist extends PureComponent<Props> {
        render() {
            const { songs, songId, playing, onToggle} = this.props;
            return (
                <Card>
                    <CardHeader title={`Load Song`}/>
                    <Divider variant="middle" />
                    <CardContent>
                        <Grid container justify="center" spacing={1}>
                            {songs.filter(({tracks}) => !!tracks.length).entrySeq().toArray().sort((a, b) => (a[1].name).localeCompare(b[1].name)).map(([id, song]) =>
                                <Grid key={id} item>
                                    <SongButton song={song} onClick={() => onToggle(id)}
                                                id={id}
                                                active={id === songId}
                                                playing={id === songId && playing}/>
                                </Grid>)}
                        </Grid>
                    </CardContent>
                </Card>

            );
        }
    });

const mapStateToProps = (state: AppState) => ({
    songId: state.songId,
    playing: state.playing,
    loading: state.loading,
    playlist: state.playlist || {name: 'none'},
    songs: state.songs,
});


export default connect(mapStateToProps, null)(DecoratedAudioContainer);
