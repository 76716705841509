import { ITrackBuffer } from "../../TrackBufferRecord";
import { Component } from "react";
import { connect } from "react-redux";

import {
  AppState,
  stopSongAction,
  updateSongAction,
  LoopingState,
  playFromSuccessAction,
  LoadProgress,
} from "../../_store";
import { ISongData } from "../../AudioBufferLoader";
import { timeout } from "../../_shared/utils";
import { Loop, TimeLoop } from "./LoopRecord";
import {
  msLoopSelector,
  playAbleSongSelector,
  trackBufferSelector,
} from "../../_store/selector";
import MixerChannel from "../../MixerChannel";
import { Map } from "immutable";
import { getAudioChannels } from "../../_shared/audioUtils";
// import React from "react";
// import {Button} from "@material-ui/core";

interface Props {
  context: AudioContext;
  playFrom: number | null;
  onPlayFromSuccess: (startedAt: number) => void;
  song: ISongData;
  songId: string;
  onUpdateSong: (ISongData) => void;
  channels: { master: MixerChannel; [key: string]: MixerChannel };
  trackBuffers: Map<string, ITrackBuffer>;
  onPlayFrom: (payload: { time: number }) => void;
  onStopSong: () => void;
  loop?: TimeLoop;
  playing: boolean;
  looping: LoopingState;
  loading: null | LoadProgress;
}

interface State {
  toggling: boolean;
  log: Array<string>;
}

class SongPlayer extends Component<Props, State> {
  startedAt: number;
  pausedAt: number;

  constructor(props) {
    super(props);
    this.context = props.context;
    this.state = {
      toggling: false,
      log: [],
    };
    this.init();
  }

  init = () => {
    this.setMasterLevel();
  };

  log = (txt) => {
    // this.setState((prevState)=>({log:prevState.log.concat([`${new Date().toISOString()} ,${txt}`])}));
  };

  /**
   * @param {Readonly<P>} prevProps
   */
  componentDidUpdate(prevProps) {
    const { song, playing, looping, loop } = this.props;
    if (!song) return;
    //switching songs
    if ((!prevProps.song || song.id !== prevProps.song.id) && playing) {
      this.playSong();
    } else if (playing !== prevProps.playing) {
      playing ? this.playSong() : this.stopSong();
    } else if (looping !== prevProps.looping || loop !== prevProps.loop) {
      playing && this.changeLoop();
    }
    // skipping ms
    else if (
      this.props.playFrom !== null &&
      this.props.playFrom !== prevProps.playFrom
    ) {
      this.playSongFrom(this.props.playFrom);
    }
  }

  toggle = (): Promise<void> => {
    const { song, playing } = this.props;
    this.setState({ toggling: true });
    if (playing) {
      this.log(`change ${song.id} to play`);
      return new Promise((resolve, reject) => {
        this.playSong();
      });
    } else {
      this.log(`change ${song.id} to stop`);
      return this.stopSong();
    }
  };
  playSong = () => this.playSongFrom(this.props.playFrom);
  setMasterLevel = () => {
    this.props.channels.master.gain.gain.value =
      (this.props.channels.master.level / 100) *
      (this.props.channels.master.mute ? 0 : 1);
  };
  getMasterLevel = (): number =>
    (this.props.channels.master.level / 100) *
    (this.props.channels.master.mute ? 0 : 1);
  destroyTracks = () => {
    const audioChannels = getAudioChannels(this.props);
    if (audioChannels.length) {
      //removing old stopSong handler
      audioChannels[0].setOnEnd(null);
    }
    for (let track of audioChannels) {
      track.stopAudio(1);
    }
  };

  playTracks = (offset) => {
    const { context, loop, looping, trackBuffers } = this.props;

    const audioChannels = getAudioChannels(this.props);
    for (let channel of audioChannels) {
      channel.prepareAudio(
        trackBuffers.get(`track_${channel.id}`).buffer,
        looping ? loop : null,
        offset
      );
    }
    for (let channel of audioChannels) {
      channel.startAudio();
    }
    // No level up needed when masterGainNode.value  is set above
    const masterLevel = this.getMasterLevel();
    if (masterLevel > 0.00000000001) {
      this.masterGainNode().gain.exponentialRampToValueAtTime(
        this.getMasterLevel(),
        context.currentTime + 0.008
      );
    }
    this.startedAt = context.currentTime - offset;
    //setting stopSong handler
    if (audioChannels.length) {
      audioChannels[0].setOnEnd(this.onSongEnded);
    }
  };

  changeLoop = (looping?: boolean, loop?: Loop) => {
    //TODO what is the current songPosition
    const currentOffset = this.props.context.currentTime - this.startedAt;
    this.log("recreating loop ");
    this.destroyTracks();
    this.playTracks(currentOffset);
    //Signal to state to make it possible to jump to the same ms multiple times
    this.props.onPlayFromSuccess(this.startedAt);
  };
  playSongFrom = (offset: number) => {
    this.log("playSong from " + offset);
    this.destroyTracks();
    this.playTracks(offset);
    //Signal to state to make it possible to jump to the same ms multiple times
    this.props.onPlayFromSuccess(this.startedAt);
  };
  onSongEnded = (e: Event) => {
    if (this.props.looping === LoopingState.single) {
      this.playSongFrom(0);
    } else {
      this.props.onStopSong();
    }
  };

  pause = (): void => {
    this.stopSong().then(() => this.log("song stopped"));
  };
  masterGainNode = () => this.props.channels.master.gain;
  /**

     smooth fadeout on change
     **/
  stopSong = async (): Promise<void> => {
    const { context } = this.props;
    this.log(`stopping`);

    const audioChannels = getAudioChannels(this.props);
    if (audioChannels.length) {
      //removing old stopSong handler
      // this.log(this.trackChannels.first().source.onended);
      audioChannels[0].setOnEnd(null);
      this.masterGainNode().gain.setTargetAtTime(0, context.currentTime, 0.5);
      return timeout(55).then(() => {
        for (let channel of audioChannels) {
          channel.stopAudio();
        }
        this.log("stopped");
      });
    } else {
      return timeout(0).then(() => {});
    }
  };

  render() {
    // return (
    // <div className={'log'}><Button onClick={()=>this.setState({log:[]})}>clear</Button> LOG:{this.state.log.map((entry,index)=><div key={index}>{entry}</div>)}</div>);
    return null;
  }
}

const mapStateToProps = (state: AppState) => ({
  context: state.audioContext,
  bufferLoader: state.bufferLoader,
  channels: state.channels,
  trackBuffers: trackBufferSelector(state),
  songId: state.songId,
  song: playAbleSongSelector(state),
  playFrom: state.playFrom,
  playing: state.playing,
  loading: state.loading,
  loop: msLoopSelector(state),
  pausedAt: state.pausedAt,
  startedAt: state.startedAt,
  looping: state.looping,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onStopSong: () => {
    dispatch(stopSongAction());
  },
  onPlayFromSuccess: (time) => {
    dispatch(playFromSuccessAction(time));
  },
  onUpdateSong: (song: ITrackBuffer) => {
    dispatch(updateSongAction({ song }));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SongPlayer);
