import { Record } from "immutable";
import { BarPos } from "../../_reducers";

export type Marker =
  | {
      id: string;
      label: string;
      pos: number;
      type: "ms";
    }
  | {
      id: string;
      label: string;
      pos: BarPos;
      type: "bar";
    };
type PartialMarker = Partial<Marker>;
export type IMarker = Marker & {
  set<K extends keyof Marker>(key: K, value: Marker[K]): IMarker;
  getIn(keyPath: ["contents", string]): Marker | undefined;
  setIn(keyPath: ["contents", string], value: Marker): IMarker;
  deleteIn(keyPath: ["contents", string]): IMarker;

  withMutations(mutator: (s: IMarker) => any): IMarker;

  // Merge is made easy using typescript's new mapped types!!!
  merge(partial: PartialMarker): IMarker;
};
export const PosMarkerRecord = Record({
  id: "",
  label: "marker",
  pos: [0, 0, 0, 0],
  type: "bar",
} as Marker);

export const TimeMarkerRecord = Record({
  id: "",
  label: "marker",
  pos: 0,
  type: "ms",
} as Marker);
