import {call, put, takeLatest} from 'redux-saga/effects'
import * as api from '../_shared/api';
import {playlistConstants} from "../_shared/constants";

// worker Saga: will be fired on PLAYLIST_FETCH_REQUESTED actions
function* fetchPlaylistRequested(action) {
    try {
        const playlist = yield call(api.fetchPlaylist);
        yield put({type: playlistConstants.FETCH_SUCCESS, playlist});
    } catch ({message}) {
        yield put({type: playlistConstants.FETCH_FAILURE, message});
    }
}


function* playListSaga() {
    yield takeLatest(playlistConstants.FETCH_REQUEST, fetchPlaylistRequested);
}

export default playListSaga;