import React from "react";
import { fontFamilies } from "../../_shared/constants";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useInputLabelStyles = makeStyles({
  root: {
    color: "white",
  },
  shrink: {
    color: "white!important",
    // display:'none',
    transform: "translate(0,-6px) scale(0.6)",
  },
});
const useFieldStyles = makeStyles({
  root: {
    color: "white",
    backgroundColor: "inherit",
  },
});
const useInputStyles = (width) =>
  makeStyles({
    root: {
      color: "white",
      fontFamily: `"${fontFamilies.monospace}", monospace`,
      width: `${width}px`,
      margin: "0!important",
      padding: 0,
    },
    input: {
      width: `${width}px`,
      padding: 0,
    },
    underline: {
      "&:after": {
        borderBottomWidth: "1px",
        borderBottomColor: "white",
      },
    },
    focused: {
      color: "white",
      // height:'10px',
      padding: 0,
      margin: 0,
    },
    // underline: {
    //     '&:after': {
    //         borderBottomColor: 'white'
    //     }
    // },
  });

export default function SongPosTextField(props) {
  const inputClasses = useInputStyles(props.width || 60)();
  const fieldClasses = useFieldStyles();
  const inputLabelClasses = useInputLabelStyles();

  return (
    <TextField
      InputProps={{ classes: inputClasses }}
      // SelectProps={}
      InputLabelProps={{ classes: inputLabelClasses }}
      {...props}
      classes={fieldClasses}
    />
  );
}
