import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  createStyles,
  Divider,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import { StateSwitch } from "./StateSwitches";
import Fader from "./Fader";
import { IMixerChannel } from "../../_store";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { withSongPosition } from "../../_shared/withSongPosition";
import useAnimationFrame from "../../hooks/useAnimationFrame";
import { calcNiceLevel } from "../../_shared/audioUtils";
import MixerChannel from "../../MixerChannel";

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    header: {
      textAlign: "center",
      spacing: 10,
      fontSize: "1.1em",
    },
    sliderList: {
      display: "flex",
      // touchAction: 'none',
      flexDirection: "column",
      padding: spacing(1),
      justifyContent: "center",
      alignItems: "center",
    },
    track: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "40px",
      margin: "1px",
    },
    channelControl: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "3px",
      minWidth: "200px",
    },
    channel: {
      display: "flex",
    },
    default: {
      color: palette.grey["200"],
      backgroundColor: palette.grey["200"],
    },
    mute: {
      backgroundColor: red.A400,
      "&:hover": {
        backgroundColor: red.A700,
      },
    },
    unmute: {
      backgroundColor: palette.grey["50"],
    },
    solo: {
      backgroundColor: yellow.A400,
      "&:hover": {
        backgroundColor: yellow.A700,
      },
    },
    unsolo: {
      backgroundColor: palette.grey["50"],
    },
  });

interface Props extends WithStyles<typeof styles> {
  channels: { [key: string]: MixerChannel };
}

// interface State {
//     focus: boolean,
//     addRemove: number,
//     visible: boolean,
// }

function Mixer({ classes, channels }: Props) {
  useEffect(() => {
    setPeaks(
      Object.keys(channels).reduce((prev, curr) => ({ ...prev, [curr]: 0 }), {})
    );
  }, [channels]);

  const [visible, setVisible] = useState(true);
  const [peaks, setPeaks] = useState(
    Object.keys(channels).reduce((prev, curr) => ({ ...prev, [curr]: 0 }), {})
  );
  useAnimationFrame((deltaTime) => {
    setPeaks(
      Object.keys(channels).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: calcNiceLevel(channels[curr].analyserData),
        }),
        {}
      )
    );
  });

  function onChangeSolo(id, solo) {
    channels[id].update({ solo });
    const channelList: Array<MixerChannel> = Object.values(channels);
    for (let ch of channelList) {
      ch.setLevel(isInSoloMode());
    }
  }
  function isInSoloMode() {
    const channelList: Array<MixerChannel> = Object.values(channels);
    return !!channelList.find(({ solo }) => solo);
  }

  return (
    <Card variant={"outlined"}>
      <CardHeader
        title={"Mixer"}
        action={
          <StateSwitch
            data={{ id: "mixer", active: visible }}
            onChange={setVisible}
          />
        }
      />
      {visible && (
        <>
          <Divider variant="middle" />
          <CardContent>
            {Object.values(channels)
              .sort(sortByChannelType)
              .map(({ level, mute, solo, name, id, type }) => (
                <div key={id} className={classes.track}>
                  <Fader
                    level={peaks[id]}
                    value={level}
                    onChange={(value) =>
                      channels[id].update({ level: value }, isInSoloMode())
                    }
                  />
                  <div className={classes.channelControl}>
                    <ButtonGroup variant={"outlined"}>
                      <Button
                        onClick={() => channels[id].update({ mute: !mute })}
                        className={
                          mute && !solo ? classes.mute : classes.unmute
                        }
                        variant={"outlined"}
                        disableElevation
                        size={"small"}
                      >
                        Mute
                      </Button>
                      {type !== "master" && (
                        <Button
                          variant={"outlined"}
                          onClick={() => onChangeSolo(id, !solo)}
                          className={solo ? classes.solo : classes.unsolo}
                          disableElevation
                        >
                          Solo
                        </Button>
                      )}
                    </ButtonGroup>
                    <Typography id="label">{name}</Typography>
                  </div>
                </div>
              ))}
          </CardContent>
        </>
      )}
    </Card>
  );
}

function sortByChannelType(
  { type: typeA }: IMixerChannel,
  { type: typeB }: IMixerChannel
) {
  if (typeA === "master") return -1;
  if (typeB === "master") return 1;
  return 0;
}

export default withSongPosition(withStyles(styles)(Mixer));
