import * as React from "react";
import { useCallback, useRef, useState } from "react";
import { secondsToString } from "../../_shared/utils";
import {
  Button,
  ListItem,
  Input,
  InputLabel,
  Icon,
  ButtonGroup,
  withStyles,
  IconButton,
} from "@material-ui/core";
import { IMarker, Marker } from "./MarkerRecord";
import { barPosToString } from "./SongPosition";
import { green } from "@material-ui/core/colors";
import styled from "styled-components";
import PosInput from "./PosInput";
import { BarPos } from "../../_reducers";

const TimeLabel = styled.span`
  min-width: 60px;
`;
const MarkerItemData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  label {
    width: 100%;
  }
`;
interface Props {
  marker: IMarker;
  onPlayFromMarker: (marker: IMarker) => void;
  onMarkerAsLoopStart: (marker: IMarker) => void;
  onMarkerAsLoopEnd: (marker: IMarker) => void;
  onUpdate: (marker: Partial<IMarker>) => void;
  onDelete: (markerId: string) => void;
}
const LoopSetButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);

function MarkerItem({
  marker,
  onPlayFromMarker,
  onMarkerAsLoopStart,
  onMarkerAsLoopEnd,
  onUpdate,
  onDelete,
}: Props) {
  const labelRef = useRef(null);
  const onSetLeft = useCallback(() => {
    onMarkerAsLoopStart(marker);
  }, [marker, onMarkerAsLoopStart]);
  const onSetRight = useCallback(() => {
    onMarkerAsLoopEnd(marker);
  }, [marker, onMarkerAsLoopEnd]);
  const onPlayFrom = useCallback(() => {
    onPlayFromMarker(marker);
  }, [marker, onPlayFromMarker]);
  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const keyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === "Enter") {
        setIsEditingLabel(false);
        debugger;
        if (labelRef) {
          onUpdate(marker.set("label", labelRef.current?.value || 0));
        }
      }
    },
    [onUpdate, labelRef, marker]
  );

  const onSetTime = useCallback(
    (val: BarPos | number) => {
      onUpdate(marker.set("pos", val));
    },
    [marker, onUpdate]
  );
  return (
    <ListItem>
      <ButtonGroup>
        <Button variant="contained" color="primary" onClick={onPlayFrom}>
          <Icon>play_arrow</Icon>
        </Button>
        <LoopSetButton onClick={onSetLeft} variant="contained">
          L
        </LoopSetButton>
        <LoopSetButton onClick={onSetRight} variant="contained">
          R
        </LoopSetButton>
      </ButtonGroup>
      <MarkerItemData>
        <div style={{ marginLeft: "1em" }}>
          <PosInput value={marker.pos} onSetPos={onSetTime} />
        </div>
        <div>
          {isEditingLabel ? (
            <Input
              inputRef={labelRef}
              autoFocus
              defaultValue={marker.label}
              onKeyDown={keyPress}
              onBlur={(e) => {
                setIsEditingLabel(false);
                onUpdate(marker.set("label", e.currentTarget.value));
              }}
            />
          ) : (
            <InputLabel onClick={() => setIsEditingLabel(true)}>
              {marker.label || "enter label"}
            </InputLabel>
          )}
        </div>
        <IconButton
          onClick={() => onDelete(marker.id)}
          color="secondary"
          aria-label="delete"
        >
          <Icon>delete</Icon>
        </IconButton>
      </MarkerItemData>
    </ListItem>
  );
}

export default MarkerItem;

export function MarkerTime({ marker }: { marker: Marker }) {
  const label =
    marker.type === "ms"
      ? secondsToString(marker.pos / 1000)
      : barPosToString(marker.pos);
  return <TimeLabel>{label}</TimeLabel>;
}
