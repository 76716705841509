import React from "react";
import { Route, Redirect } from 'react-router-dom';


export const PrivateAppliedRoute = ({ component: Component,appProps, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} {...appProps} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
);

