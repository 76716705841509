// import React, {useState} from 'react';
import React from 'react';
import PDF from 'react-pdf-js-infinite';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function SheetView({sheets}) {
    // const [numPages, setNumPages] = useState(null);
    // const [pageNumber, setPageNumber] = useState(0);
    //
    // const onDocumentLoadSuccess = ({numPages}) => {
    //     setNumPages(numPages)
    // };

    return (sheets.map(({file, name}, index) => <div key={index}>
        <div className={'pdf'} style={{textAlign:'center'}}>
            <PDF file={file} scale={2} />
        </div>
        {/*<p>Page {pageNumber} of {numPages}</p>*/}
    </div>));
}

export default SheetView;