import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import { fontFamilies } from "../../_shared/constants";
import SongPosTextField from "./SongPosTextField";
import { BarPos } from "../../_reducers";
import PosLabel from "./PosLabel";
import { posToString, stringToPos } from "../../_shared/utils";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    fontFamily: `"${fontFamilies.monospace}", monospace`,
    display: "flex",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    backgroundColor: palette.grey["600"],
    padding: 0,
  },
  timer: {
    padding: spacing(1),
    position: "relative",
    color: "white",
    textAlign: "center",
  },
}));

interface Props<V = BarPos | number> {
  value: V;
  label?: React.ReactNode;
  duration?: number;
  onSetPos: (pos: V) => void;
}

function PosInput({ value, onSetPos, label }: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [posInput, setPosInput] = useState("");
  const classes = useStyles();
  useEffect(() => {
    setPosInput(posToString(value));
  }, [value]);
  const parsePosInputAsString = useCallback(
    (e) => {
      if (typeof value === "number") {
        setPosInput(e.target.value);
      } else {
        setPosInput(e.target.value);
      }
    },
    [setPosInput, value]
  );
  const parsePosInputAsPos = useCallback(() => {
    const gridMode = typeof value === "number" ? "ms" : "bar";
    onSetPos(stringToPos(posInput, gridMode));
  }, [onSetPos, posInput, value]);
  const posLabel = <PosLabel value={value} />;
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          className={classes.timer}
          onClick={() => {
            setIsEditing(true);
          }}
          onBlur={() => setIsEditing(false)}
        >
          {!isEditing && (
            <>
              {label || null}
              {posLabel}
            </>
          )}
          {isEditing && (
            <SongPosTextField
              autoFocus
              type="text"
              label={posLabel}
              onChange={parsePosInputAsString}
              value={posInput}
              onBlur={() => {
                parsePosInputAsPos();
                setIsEditing(false);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  parsePosInputAsPos();
                  setIsEditing(false);
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PosInput;
