import {authHeader} from "./utils";



const API_URL = process.env.API_URL || 'http://localhost:8000/api';
// const API_URL = `${BASE_URL}/api`;
const apiEndpoints = {
    lastPlaylist: `${API_URL}/playlist/last`,
    playlists: `${API_URL}/playlist/last`,
    login: `${API_URL}/auth/token/login`,
    notes: `${API_URL}/notes`,
    marker: `${API_URL}/marker`,
    currentUser: `${API_URL}/user/current`
};

const apiGet = async (url: string) => {
    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    });
    return await handleResponse(response);
};

const apiPost = async (url: string, body) => {
    const response = await fetch(url, {
        method: 'post', body: JSON.stringify(body), headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    });
    return await handleResponse(response);
};

const fetchPlaylist = async () => apiGet(apiEndpoints.lastPlaylist);
const fetchNotes = async () => apiGet(apiEndpoints.notes);
const fetchCurrentUser = async () => apiGet(apiEndpoints.currentUser);

// eslint-disable-next-line
const addMarker = async (marker) => apiPost(apiEndpoints.marker,marker);

interface Credentials {
    username: string,
    password: string
}

const userLogin = async ({username, password}: Credentials) => {
    const requestOptions = {
        credentials: "omit" as "omit",
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username, password})
    };
    return fetch(apiEndpoints.login, requestOptions)
        .then(handleResponse).then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
};

function userLogout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.location.reload(true);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userLogout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export {fetchPlaylist, userLogin, fetchNotes,userLogout,fetchCurrentUser};