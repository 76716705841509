import { all } from 'redux-saga/effects'

import playListSaga from './playlistSaga';
import loginSaga from './loginSaga';
import notesSaga from "./notesSaga";
import currentUserSaga from "./currentUserSaga";
import loadSongSaga from "./loadSongSaga";

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        playListSaga(),
        loginSaga(),
        notesSaga(),
        currentUserSaga(),
        loadSongSaga()
    ])
}