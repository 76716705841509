import { createSelector } from "reselect";
import { Map } from "immutable";
import { getMsFromBarPos } from "../_shared/timeUtils";
import { Loop, TimeLoop } from "../container/Player/LoopRecord";
import { BarPos } from "../_reducers";
import { AppState } from "./index";
// const playingSelector = state => state.playing;
// const startedAtSelector = state => state.startedAt;
// const pausedAtSelector = state => state.pausedAt;
const songsSelector = (state) => state.songs;
const loopSelector = (state) => state.loop;

const songIdSelector = (state) => state.songId;
const bufferLoaderSelector = (state) => state.bufferLoader;
// const channelsSelector = state => state.channels;
export const songSelector = createSelector(
  songsSelector,
  songIdSelector,
  (songs, songId) => songs.get(songId)
);
export const timeTrackSelector = createSelector(songSelector, (song) =>
  song ? song.meta.timeTrack : []
);
export const playAbleSongSelector = createSelector(songSelector, (song) =>
  song && song.loaded ? song : null
);
export const msLoopSelector: (state: AppState) => TimeLoop = createSelector(
  songSelector,
  loopSelector,
  (song, loop: Loop) => {
    let { type, from, to } = loop;
    if (type === "bar" && song?.meta.timeTrack.length) {
      from = getMsFromBarPos(from as BarPos, song.meta.timeTrack) / 1000;
      to = getMsFromBarPos(to as BarPos, song.meta.timeTrack) / 1000;
    }
    return { ...loop, type: "ms", from, to } as TimeLoop;
  }
);

export const trackBufferSelector = createSelector(
  songSelector,
  bufferLoaderSelector,
  (song, bufferLoader) =>
    song && song.loaded ? bufferLoader.getSongBy(song.id) : Map()
);
