import React, { Component } from "react";
import Playlist from "./Playlist";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/es/Grid/Grid";
import { connect } from "react-redux";
import {
  AppState,
  changeLoopingAction,
  fetchNotesAction,
  fetchPlayListAction,
  LoadProgress,
  LoopingState,
  playFromAction,
  startSongAction,
  stopSongAction,
  updateSongAction,
  User,
  VoiceGroup,
  IMixerChannel,
  loadSongStartAction,
  selectSongAction,
  GridMode,
  changeGridModeAction,
} from "../../_store";
import AudioBufferLoader, { ISongData } from "../../AudioBufferLoader";
import { List } from "immutable";
import Mixer from "./Mixer";
import PlayControls from "./PlayControls";
import SongView from "../SongView/SongView";
import { IMarker, Marker } from "./MarkerRecord";
import {
  addMarkerAction,
  changeLoopAction,
  deleteMarkerAction,
  fetchCurrentUserAction,
  updateChannelAction,
  updateChannelsAction,
  updateMarkerAction,
} from "../../_actions/actions";
import NotesView from "../../components/NotesView";
import StateSwitches from "./StateSwitches";
import SheetView from "./SheetView";
import { msLoopSelector, songSelector } from "../../_store/selector";
import { Loop, TimeLoop } from "./LoopRecord";
import Sticky from "./Sticky";
import LoadProgressBar from "./LoadProgressBar";
import MarkerList from "./MarkerList";
// import GroupSelect from "../GroupSelect/GroupSelect";

const styles = (theme) => ({
  root: {
    textAlign: "left" as "left",
    flexGrow: 1,
    color: "#555",
  },
  header: {
    textAlign: "center",
    spacing: 10,
    fontSize: "1.0em",
  },
  title: {
    textAlign: "center",
    fontSize: "1.5rem",
  },
});

interface Props {
  audioCtx: AudioContext;
  bufferLoader: AudioBufferLoader;
  gridMode: GridMode;
  onChangeGridMode: (gridMode: GridMode) => void;
  onChangeLoop: (l: Loop) => void;
  loop: TimeLoop;
  classes: any;
  songId: string;
  song?: ISongData;
  songs: Map<string, ISongData>;
  onPlayFrom: (time: number) => void;
  onStartSong: (id: string, time: number, playFrom?: number) => void;
  onStopSong: () => void;
  playFrom: number | null;
  onUpdate: (payload) => void;
  looping: LoopingState;
  playing: boolean;
  loading: LoadProgress | null;
  onLoadSong: ({ song: ISong, bufferLoader: AudioBufferLoader }) => void;
  onSelectSong: (songId) => void;
  channels: { [key: string]: IMixerChannel };
  onUpdateChannel: (id: string, data: Partial<IMixerChannel>) => void;
  onChangeLooping: (looping: LoopingState) => void;
  startedAt: number;
  groups: Array<VoiceGroup>;
  pausedAt: number;
  onFetchPlaylist: () => void;
  onFetchCurrentUser: () => void;
  onFetchNotes: () => void;
  marker: List<IMarker>;
  notes: Array<{ note: string }>;
  user?: User;
  elapsed: Number;
  onSetElapsed: (elapsed: number) => void;
  onAddMarker: (id: string, marker: Marker) => void;
  onUpdateMarker: (id: string, marker: Marker) => void;
  onDeleteMarker: (id: string, markerId: string) => void;
}

interface State {
  visibilities: { [id: string]: boolean };
}

function getComponentLabel(id: string) {
  return `${id}`;
}

class PlayViewContainer extends Component<Props, State> {
  state = {
    visibilities: {
      // groups:false,
      tracks: true,
      songs: true,
      // playList: true,
      notes: true,
    },
  };

  componentDidMount() {
    this.props.onFetchPlaylist();
    this.props.onFetchNotes();
    this.props.onFetchCurrentUser();
  }

  handleSelectSong = (songId: string) => {
    if (this.props.songId === songId) {
      if (this.props.playing) {
        console.log("stopSong " + songId);
        this.props.onStopSong();
      } else {
        console.log("playSong " + songId);
        this.props.onStartSong(songId, 0);
      }
    } else {
      const { songs } = this.props;
      const song = songs.get(songId);
      if (!song.loaded) {
        const { bufferLoader } = this.props;

        this.props.onLoadSong({ song, bufferLoader });
      } else {
        this.props.onStartSong(songId, this.props.pausedAt);
      }
    }
  };

  _trackBuffers = () => {
    if (
      this.props.songId &&
      this.props.bufferLoader.bufferMap.has(this.props.songId)
    ) {
      return this.props.bufferLoader.bufferMap
        .get(this.props.songId)
        .valueSeq()
        .toArray();
    }
    return [];
  };

  _songDuration(): number {
    if (!this._trackBuffers().length) {
      return 0;
    }
    return (
      this._trackBuffers()[0].buffer && this._trackBuffers()[0].buffer.duration
    );
  }

  changeVisibility = ({ id, active }) => {
    this.setState((prevState, props) => ({
      visibilities: {
        ...prevState.visibilities,
        [id]: active,
      },
    }));
  };

  render() {
    const {
      classes,
      user,
      notes,
      looping,
      loop,
      song,
      onChangeLooping,
      audioCtx,
      onUpdateChannel,
      onAddMarker,
      gridMode,
      onChangeGridMode,
      onUpdateMarker,
      onDeleteMarker,
      onChangeLoop,
      channels,
      onPlayFrom,
      playing,
      startedAt,
      marker,
    } = this.props;
    const { visibilities } = this.state;
    if (!user) {
      return null;
    }
    const trackBuffers = this._trackBuffers();
    const duration = this._songDuration();
    return (
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12}>
          <StateSwitches
            switchStates={Object.entries(visibilities).map(([id, active]) => ({
              id,
              label: getComponentLabel(id),
              active,
            }))}
            onChange={this.changeVisibility}
          />
        </Grid>

        {/*{visibilities.groups && <Grid item md={6}>*/}
        {/*    <GroupSelect/>*/}
        {/*</Grid>}*/}
        {visibilities.notes && (
          <Grid item xs={12} md={6}>
            <NotesView notes={notes} />
          </Grid>
        )}
        {visibilities.songs && (
          <Grid item xs={12} md={6}>
            <Playlist onToggle={this.handleSelectSong} />
          </Grid>
        )}
        {visibilities.tracks && !!song && (
          <Grid xs={12} item>
            <SongView
              onPlayFrom={onPlayFrom}
              duration={duration}
              gridMode={gridMode}
              looping={looping}
              onChangeLoop={onChangeLoop}
              loop={loop}
              timeTrack={song.meta.timeTrack}
              height={
                visibilities.tracks
                  ? Math.min(200, trackBuffers.length * 50)
                  : 0
              }
              tracks={trackBuffers}
              marker={marker}
            />
          </Grid>
        )}
        <Grid md={6} item>
          <LoadProgressBar />
          {!!song && (
            <Sticky top={52}>
              <PlayControls
                startedAt={startedAt}
                gridMode={gridMode}
                onChangeGridMode={onChangeGridMode}
                looping={looping}
                onChangeLooping={onChangeLooping}
                id={song.id}
                song={song}
                toggle={() => this.handleSelectSong(song.id)}
                onAddMarker={onAddMarker}
                onUpdateMarker={onUpdateMarker}
                onDeleteMarker={onDeleteMarker}
                audioCtx={audioCtx}
                playing={playing}
                onPlayFrom={onPlayFrom}
                marker={marker}
              />
            </Sticky>
          )}
          {!!song && (
            <MarkerList
              marker={marker}
              song={song}
              onAddMarker={onAddMarker}
              onDeleteMarker={onDeleteMarker}
              onPlayFromS={onPlayFrom}
              onUpdateMarker={onUpdateMarker}
            />
          )}
        </Grid>
        <Grid md={6} item>
          {channels && (
            <Mixer channels={channels} onUpdateChannel={onUpdateChannel} />
          )}
          {!!song && <Grid md={12} item />}
        </Grid>
        {!!song && (
          <Grid md={12} item>
            <SheetView sheets={song.sheets} />
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  channels: state.channels,
  audioCtx: state.audioContext,
  gridMode: state.gridMode,
  loop: msLoopSelector(state),
  bufferLoader: state.bufferLoader,
  notes: state.notes,
  user: state.user,
  songs: state.songs,
  songId: state.songId,
  song: songSelector(state),
  loading: state.loading,
  marker: state.marker.get(state.songId),
  playing: state.playing,
  startedAt: state.startedAt,
  pausedAt: state.pausedAt,
  playFrom: state.playFrom,
  looping: state.looping,
});
const mapDispatchToProps = (dispatch) => ({
  onStartSong: (id: string, time?: number) => {
    dispatch(startSongAction({ id, time: time || 0 }));
  },
  onSelectSong: (id) => {
    dispatch(selectSongAction({ id }));
  },
  onPlayFrom: (time) => {
    dispatch(playFromAction({ time }));
  },
  onFetchPlaylist: () => {
    dispatch(fetchPlayListAction());
  },
  onFetchNotes: () => {
    dispatch(fetchNotesAction());
  },
  onFetchCurrentUser: () => {
    dispatch(fetchCurrentUserAction());
  },
  onChangeLooping: (looping?: LoopingState) => {
    dispatch(changeLoopingAction(looping));
  },
  onChangeGridMode: (gridMode: GridMode) => {
    dispatch(changeGridModeAction(gridMode));
  },
  onChangeLoop: (loop: Loop) => {
    dispatch(changeLoopAction(loop));
  },
  onStopSong: () => {
    dispatch(stopSongAction());
  },
  onLoadSong: ({ song, bufferLoader }) => {
    dispatch(loadSongStartAction({ song, bufferLoader }));
  },
  onUpdateSong: (song: ISongData) => {
    dispatch(updateSongAction({ song }));
  },
  onUpdateChannel: (id, data) => {
    dispatch(updateChannelAction({ id, data }));
  },
  onUpdateChannels: (id, data) => {
    dispatch(updateChannelsAction({ id, data }));
  },
  onAddMarker: (songId: string, marker: Marker) => {
    dispatch(addMarkerAction(songId, marker));
  },
  onUpdateMarker: (songId: string, marker: Marker) => {
    dispatch(updateMarkerAction(songId, marker));
  },
  onDeleteMarker: (songId: string, marker: Marker) => {
    dispatch(deleteMarkerAction(songId, marker));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error
)(withStyles(styles)(PlayViewContainer));
