import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import { Map, List } from "immutable";
import { ITrackBuffer } from "../TrackBufferRecord";
import { IMarker } from "../container/Player/MarkerRecord";
import createDebounce from "redux-debounced";
import AudioBufferLoader, { ISongData } from "../AudioBufferLoader";
import rootSaga from "../sagas";
import {
  fetchNotesAction,
  stopSongAction,
  playFromAction,
  playFromSuccessAction,
  startSongAction,
  updateSongAction,
  loadSongSuccessAction,
  selectSongAction,
  resetPlayFromAction,
  loadSongProgressAction,
  changeLoopingAction,
  loadSongStartAction,
  loadSongFailAction,
  fetchPlayListAction,
  loginAction,
  logoutAction,
  changeGridModeAction,
} from "../_actions/actions";
import { ILoop, Loop } from "../container/Player/LoopRecord";
import { initialState, rootReducer } from "../_reducers";
export type GridMode = "ms" | "bar";
export enum LoopingState {
  off,
  single,
  sequence,
}

export interface LoadProgress {
  songId: string;
  completed: [number, number];
}

export interface VoiceGroup {
  name: string;
  pk: string;
  ancestors: Array<VoiceGroup>;
}

export interface User {
  username: string;
  email: string;
  voice_group: VoiceGroup;
  available_groups: Array<VoiceGroup>;
}

export interface TrackData {
  source: AudioBufferSourceNode;
}
export type ChannelType = "group" | "master" | "midi" | "audio";

export interface IMixerChannel {
  gain: GainNode;
  analyserData: { analyser: AnalyserNode; sampleBuffer: Float32Array };
  channelCount: number;
  destination?: IMixerChannel;
  update: (data: any) => void;
  setOnEnd: (callback: any) => void;
  stopAudio: (offset?: number) => void;
  startAudio: (offset?: number) => void;
  prepareAudio: (buffer, loop, offset: number) => void;
  id: string;
  name: string;
  type: ChannelType;
  level: number;
  solo: boolean;
  pan: number;
  mute: boolean;
}

export interface Playlist {
  name: string;
}

export interface AppState {
  gridMode: GridMode;
  audioContext: AudioContext;
  bufferLoader: AudioBufferLoader;
  group?: VoiceGroup;
  channels: { master: IMixerChannel; [key: string]: IMixerChannel };
  groups: Array<VoiceGroup>;
  songId?: string;
  trackBuffer: Map<string, ITrackBuffer>;
  sequence: Array<string>;
  songs: Map<string, ISongData>;
  marker: Map<string, List<IMarker>>;
  loops: Map<string, List<ILoop>>;
  playFrom: number | null;
  playing: boolean;
  looping: LoopingState;
  loading: null | LoadProgress;
  loop?: Loop;
  playlist: null | Playlist;
  playlists: null | Array<Playlist>;
  loadingError?: string;
  startedAt: number;
  pausedAt: number;
  soundSources: Array<ISongData>;
  authToken?: string;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  notes: Array<{ note: string }>;
  user?: User;
}

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState as any,
  composeEnhancers(
    applyMiddleware(createDebounce()),
    applyMiddleware(sagaMiddleware)
  )
);
// then run the saga
sagaMiddleware.run(rootSaga);
export {
  store,
  fetchNotesAction,
  stopSongAction,
  playFromAction,
  playFromSuccessAction,
  startSongAction,
  updateSongAction,
  loadSongSuccessAction,
  selectSongAction,
  resetPlayFromAction,
  loadSongProgressAction,
  changeLoopingAction,
  loadSongStartAction,
  changeGridModeAction,
  loadSongFailAction,
  fetchPlayListAction,
  loginAction,
  logoutAction,
};
