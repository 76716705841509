import {ISongData} from "../../AudioBufferLoader";
import Button from "@material-ui/core/Button";
import React from "react";
import Icon from "@material-ui/core/es/Icon/Icon";

interface SBProps {
    song: ISongData,
    onClick: () => void,
    id: string,
    playing: boolean,
    active: boolean,
    small?: any,
}

const SongButton = ({song, onClick,  id, playing, active, small}: SBProps) =>
    (<Button variant="contained"
             color={song.loaded ? (playing ? 'primary' : 'secondary') : 'default'}
             onClick={onClick}>{song.loaded ? playing ? <Icon>stop</Icon> : <Icon>play_arrow</Icon> :
        <Icon>save_alt</Icon>}{!small && <span style={{minWidth: '150px'}}>{song.name}</span>}</Button>);


export default SongButton;