import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    children: React.ReactChild,
    top: number,
}

// stickyWrapper: {
//     position: 'relative'
//     // height: 4rem; /* probably you need to set height value for wrapper */
// }
//
// .sticky .sticky--inner {
//     position: fixed;
//     // top: 0;
//     z-index: 1;
// }
const useStyles = (top)=>makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(1)
    },
    stickyWrap: {
        position: 'relative'
    },
    sticky: {
        position: 'fixed',
        zIndex: 1200,
        top:`${top}px`
    }
}));

function Sticky({children, top}: Props) {
    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);
    const classes = useStyles(top)();
    useEffect(() => {
        const handleScroll = () => {
            if (ref && ref.current && ref.current.getBoundingClientRect()) {
                setSticky(ref.current.getBoundingClientRect().top <= top);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, [top]);



    return (
        <div className={classes.stickyWrap} ref={ref}>
            <div className={isSticky?classes.sticky:''}>
                {children}
            </div>
        </div>
    );
};

export default Sticky