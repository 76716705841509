import { ChannelType, IMixerChannel } from "./_store";
import { addTrackAnalyser, getTrackLevel } from "./_shared/audioUtils";
import { TimeLoop } from "./container/Player/LoopRecord";

type MixerChannelOptions = {
  channelCount?: number;
  destination?: IMixerChannel;
  type: ChannelType;
  level?: number;
  solo?: boolean;
  pan?: number;
  id: string;
  name?: string;
  mute?: boolean;
};

export default class MixerChannel implements IMixerChannel {
  context: AudioContext;
  offset: number;
  gain: GainNode;
  analyserData: { sampleBuffer: Float32Array; analyser: AnalyserNode };
  channelCount: number;
  id: string;
  name: string;
  source?: AudioBufferSourceNode;
  destination?: IMixerChannel;
  type: ChannelType;
  level: number;
  solo: boolean;
  pan: number;
  mute: boolean;

  setOnEnd = (callback: any) => {
    if (!this.source) {
      return;
    }
    this.source.onended = callback;
  };
  update = (data: any, isSoloed: boolean = false) => {
    const { level, mute, solo } = data;
    this.level = level !== undefined ? level : this.level;
    this.mute = mute !== undefined ? mute : this.mute;
    this.solo = solo !== undefined ? solo : this.solo;
    this.setLevel(isSoloed);
  };

  startAudio = (offset = 0) => {
    if (!this.source) {
      console.warn("start" + this.id + "with no source");
      return;
    }
    this.source.start(0, this.offset);
  };

  stopAudio = (when: number = 0) => {
    try {
      if (!this.source) {
        console.warn("stop" + this.id + "with no source");
        return;
      }
      this.source.disconnect(this.gain);

      this.source.stop(when ? this.context.currentTime + when : 0);
      // this.source.stop( 0 );
    } catch (e) {
      if (e.name === "InvalidAccessError") {
        return;
      }
      throw e;
    }
  };
  prepareAudio = (buffer: AudioBuffer, loop?: TimeLoop, offset = 0) => {
    let source = this.context.createBufferSource();
    source.buffer = buffer;
    if (loop) {
      source.loopStart = loop.from;
      source.loopEnd = loop.to;
      source.loop = true;
    }
    // const effectNode= new BiquadFilterNode(this.context,{type:'bandpass'});
    // const effectNode= this.context.createBiquadFilter();
    source.connect(this.gain);
    this.offset = offset;
    this.source = source;
  };
  setLevel = (isSoloed: boolean = false) => {
    this.gain.gain.value = getTrackLevel(this, isSoloed);
  };

  constructor(
    context,
    options: MixerChannelOptions = {
      type: "master",
      id: "master",
      name: "master",
    }
  ) {
    this.context = context;
    this.offset = 0;
    this.id = options.id;
    this.gain = context.createGain();
    this.type = options.type;
    if (options.destination) {
      this.gain.connect(options.destination.gain);
    } else if (this.type === "master") {
      this.gain.connect(context.destination);
    } else {
      throw Error("no destination for non master channel given");
    }
    this.analyserData = addTrackAnalyser(this.gain, this.context);
    this.solo = false;
    this.name = options.name || options.id;
    this.level = options.level !== undefined ? options.level : 100;
    this.setLevel();
    this.solo = false;
    this.mute = false;
    this.destination = options.destination;
  }
}
