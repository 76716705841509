import React from "react";
import {Route, Switch} from "react-router-dom";
import Login from "../container/Login/Login";
import PlayViewContainer from "../container/Player/PlayViewContainer";
import AppliedRoute from "./AppliedRoute";
import NotFound from "../container/NotFound/NotFound";
import {PrivateAppliedRoute} from "./PrivateAppliedRoute";

interface Props {
    appProps: {},
}

export default function Routes({appProps}: Props) {
    return (
        <Switch>
            <PrivateAppliedRoute path="/" exact component={PlayViewContainer} appProps={{...appProps}}/>
            <AppliedRoute path="/login" exact component={Login} appProps={appProps}/>
            { /* Finally, catch all unmatched routes */}
            <Route component={NotFound}/>
        </Switch>
    );
}