import React from "react";

const useAnimationFrame = callback => {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = React.useRef();
    const previousTimeRef = React.useRef();

    React.useEffect(() => {
        const animate = time => {
            if (previousTimeRef.current !== undefined) {
                // @ts-ignore
                const deltaTime = time - previousTimeRef.current;
                callback(deltaTime)
            }
            previousTimeRef.current = time;
            // @ts-ignore
            requestRef.current = requestAnimationFrame(animate);
        }
        // @ts-ignore
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, [callback]); // Make sure the effect runs only once
}

export default useAnimationFrame;