import { BarPos } from "../_reducers";
import { initial } from "lodash";
import { GridMode } from "../_store";

/**
 *  parses Second with fractions to string
 * @param timeFloat a frationcal second value
 * @param showMs
 */
export const secondsToString = (timeFloat, showMs = false) => {
  let msec;
  const min = ~~(timeFloat / 60);
  const sec = ~~(timeFloat % 60);
  if (showMs) {
    msec = ~~((timeFloat * 100) % 100);
  }
  return `${min}:${sec > 9 ? sec : "0" + sec}${
    showMs ? ":" + (msec > 9 ? msec : "0" + msec) : ""
  }`;
};

/**
 *  parses a time string as ms integer value
 * @param timeLabel
 */
export const stringToMs = (timeLabel: string) => {
  const parts = timeLabel.split(":").map((e) => parseInt(e));
  const sec = parts.length === 1 ? parts[0] : parts[1];
  const min = parts.length > 1 ? parts[0] : 0;
  const ms = parts.length > 2 ? parts[2] : 0;
  return min * 60000 + sec * 1000 + ms;
};

export function uuidv4() {
  // eslint-disable-next-line
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export async function sleep(fn, ...args) {
  await timeout(3000);
  return fn(...args);
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.auth_token) {
    return { Authorization: "Token  " + user.auth_token };
  } else {
    return {};
  }
}

export function nop() {}
/**
 *
 * @param value
 */
export const posToString = (value: number | BarPos): string =>
  typeof value === "number"
    ? secondsToString(value / 1000, false)
    : initial(value).join(":");

export const stringToPos = (
  value: string,
  gridMode: GridMode
): number | BarPos =>
  gridMode === "ms"
    ? stringToMs(value)
    : ([1, 1, 1, 0].map(mapper(value.split(":"))) as BarPos);

const mapper = (value: string[]) => (defaultVal, index) =>
  parseInt(value?.[index]) || defaultVal;
