import * as  React from "react";
import {userLogout} from "./_shared/api";
import {Button} from "@material-ui/core";
import {connect} from "react-redux";
import {AppState, User, VoiceGroup} from "./_store";
import {ArrowRight, MusicNote} from "@material-ui/icons";

interface Props {
    user?: User,
    group?: VoiceGroup,
}

interface VProps {
    list: Array<{ name: string }>
}

const flexStyle = {
    padding: '5px',
    fontSize: '0.8em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
};


const vgStyle = {
    fontSize: '0.7em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
};
const spanStyle = {
    padding: '0 10px',
    alignItems: 'center',
    display: 'flex'
};

/**
 *  it works but looks ugly
 * @param voice_groups
 * @constructor
 */
export function ArrowList({list}: VProps) {
    // @ts-ignore
    return <span style={vgStyle}>{list.map(el => el.name).reduce((prev: any, curr: any, index) => [prev,
        <ArrowRight key={index * 3 + 1}/>, <span key={index * 3 + 2}>{curr}</span>])}</span>
}

function UserHeader({user, group}: Props) {
    if (!user) return null;
    return (<div style={flexStyle}><span
        style={spanStyle}>Hi {user.username}<MusicNote/>{group ? group.name : ''} </span><Button variant="contained"
                                                                                                 size={"small"}
                                                                                                 color="secondary"
                                                                                                 onClick={userLogout}>Logout</Button>
    </div>);
}

const mapStateToProps = (state: AppState) => ({
    user: state.user,
    group: state.group,
});

export default connect(
    mapStateToProps, null,
)((UserHeader));
