import { BarPos } from "../../_reducers";
import React from "react";
import { posToString } from "../../_shared/utils";
import styled from "styled-components";
const PosLabelStyled = styled.span`
  padding: 0 10px;
`;

type Props = {
  value: BarPos | number;
};
const PosLabel: React.FC<Props> = ({ value }) => {
  return <PosLabelStyled>{posToString(value)}</PosLabelStyled>;
};
export default PosLabel;
