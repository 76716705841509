import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { CardContent, Divider } from "@material-ui/core";
import MarkerItem from "./MarkerItem";
import React, { useCallback, useState } from "react";
import List from "@material-ui/core/es/List/List";
import { StateSwitch } from "./StateSwitches";
import { IMarker } from "./MarkerRecord";
import { ISongData } from "../../AudioBufferLoader";
import { useDispatch, useSelector } from "react-redux";
import { timeTrackSelector } from "../../_store/selector";
import { getMsFromBarPos } from "../../_shared/timeUtils";
import { AppState } from "../../_store";
import { Loop } from "./LoopRecord";
import { changeLoopAction } from "../../_actions/actions";
import { BarPos } from "../../_reducers";
import { mapValueToGridMode } from "./LoopPlayControls";

type Props = {
  song: ISongData;
  marker: List<IMarker>;
  onPlayFromS: (time: number) => void;
  onUpdateMarker: (songId: string, marker: Partial<IMarker>) => void;
  onAddMarker: (songId: string, marker: Partial<IMarker>) => void;
  onDeleteMarker: (songId: string, markerId: string) => void;
};

function MarkerList({
  marker,
  onPlayFromS,
  onUpdateMarker,
  onDeleteMarker,
  song,
}: Props) {
  const [visible, setVisible] = useState(true);
  const timeTrack = useSelector(timeTrackSelector);
  const onUpdate = useCallback(
    (marker: Partial<IMarker>) => {
      onUpdateMarker(song.id, marker);
    },
    [onUpdateMarker, song.id]
  );
  const loop = useSelector((state: AppState) => state.loop);
  const dispatch = useDispatch();
  const updateLoop = useCallback(
    (data: Partial<Loop>) => {
      dispatch(changeLoopAction({ ...loop, ...data } as Loop));
    },
    [dispatch, loop]
  );
  const onPlayFromMarker = useCallback(
    (marker: IMarker): void => {
      const ms =
        marker.type === "ms"
          ? marker.pos
          : getMsFromBarPos(marker.pos, timeTrack);
      onPlayFromS(ms / 1000);
    },
    [timeTrack, onPlayFromS]
  );
  const onDelete = useCallback(
    (markerId: string) => onDeleteMarker(song.id, markerId),
    [onDeleteMarker, song.id]
  );
  const onMarkerAsLoopStart = useCallback(
    ({ pos }: { pos: number | BarPos }) => {
      const time = mapValueToGridMode(pos, loop.type as never, timeTrack);
      updateLoop({ ...loop, from: time } as Loop);
    },
    [updateLoop, loop, timeTrack]
  );

  const onMarkerAsLoopEnd = useCallback(
    ({ pos }: { pos: number | BarPos }) => {
      const time = mapValueToGridMode(pos, loop.type as never, timeTrack);
      updateLoop({ ...loop, to: time } as Loop);
    },
    [updateLoop, loop, timeTrack]
  );

  return (
    <Card>
      <CardHeader
        title={`Marker`}
        action={
          <StateSwitch
            data={{ id: "mixer", active: visible }}
            onChange={setVisible}
          />
        }
      />
      {visible && (
        <>
          <Divider variant="middle" />
          <Divider variant="middle" />
          <CardContent>
            <List>
              {marker
                .entrySeq()
                .toArray()
                .map(([id, marker]) => (
                  <MarkerItem
                    onPlayFromMarker={onPlayFromMarker}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    key={id}
                    marker={marker}
                    onMarkerAsLoopEnd={onMarkerAsLoopEnd}
                    onMarkerAsLoopStart={onMarkerAsLoopStart}
                  />
                ))}
            </List>
          </CardContent>
        </>
      )}
    </Card>
  );
}

export default MarkerList;
