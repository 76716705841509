import { buffers, eventChannel, END } from 'redux-saga';
import AudioBufferLoader, {ISongData} from "../AudioBufferLoader";
function createLoadSongChannel(bufferLoader: AudioBufferLoader, song:ISongData) {
    return eventChannel(emitter => {
        const onProgress = emitter;
        // const onFailure = (e: ProgressEvent) => {
        //     emitter({ err: new Error('Upload failed') });
        //     emitter(END);
        // };
        bufferLoader.loadSong(song,onProgress).then(({song,error})=> {
            emitter({song, error})
            emitter(END);
        }
    )
        return () => {

        };
    }, buffers.sliding(2));
}

export default createLoadSongChannel