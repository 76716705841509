import React, {Component} from "react";
import {ITrackBuffer} from "../../TrackBufferRecord";

interface Props {
    track: ITrackBuffer
    width: number,
    height: number,
    top: number,
}

interface State {
    canvasCtx: CanvasRenderingContext2D | null,
    trigger: number,
}


export default class WaveformLayer extends Component<Props, State> {

    canvas: HTMLCanvasElement;
    state: {
        canvasCtx: null,
        trigger: 0
    }

    componentDidMount() {
        const canvasCtx = this.canvas.getContext('2d');
        this.setState({canvasCtx,trigger:Math.random()});
    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.width!==this.props.width||prevProps.height!==this.props.height) {
            const canvasCtx = this.canvas.getContext('2d');
            this.setState({canvasCtx,trigger:Math.random()});
        }
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
        return (!this.state && !!nextState)
            || (nextProps.track && this.props.track && nextProps.track.buffer !== this.props.track.buffer)
            || (nextProps.height !== this.props.height)
            || (nextProps.width !== this.props.width)
            || (nextState.trigger !== this.state.trigger);
    }

    displayBuffer = (buff: AudioBuffer, context: CanvasRenderingContext2D) => {
        const {height, width} = this.props;
        const drawLines = 4000;
        const leftChannel = buff.getChannelData(0); // Float32Array describing left channel
        // let lineOpacity = width / leftChannel.length  ;
        context.save();
        context.fillStyle = '#eeeeee';
        // context.fillStyle = '#ff00ff';
        context.fillRect(0, 0, width, height);
        context.strokeStyle = 'rgba(32,106,186,0.4)';
        context.globalCompositeOperation = 'darker';
        context.translate(0, height / 2);
        //context.globalAlpha = 0.6 ; // lineOpacity ;
        context.lineWidth = 1;
        const songLength = leftChannel.length;
        const eachBlock = Math.floor(songLength / drawLines);
        //gap needs to be referring to the amount of canvas used for the audio (to align with the timeslider)
        const canvasSongPercentage = 1;
        const songWidth = width * canvasSongPercentage;
        const lineGap = (songWidth / drawLines);
        context.fillStyle = '#fff';
        context.fillRect(0, 0, songWidth, height);
        context.beginPath();
        for (let i = 0; i <= drawLines; i++) {
            let audioBuffKey = Math.floor(eachBlock * i);
            let x = i * lineGap;
            let y = leftChannel[audioBuffKey] * height / 2;
            context.moveTo(x, y);
            context.lineTo(x, (y * -1));
        }
        context.stroke();
        context.restore();
    };

    render() {
        const {height, track, width, top} = this.props;
        if (track && track.buffer && this.state) {
            // console.log('displayBuffer',height,track.name);
            this.displayBuffer(track.buffer, this.state.canvasCtx);
        }
        return <canvas width={width} height={height}
                       style={{position: 'absolute',left:0, top: `${top}px`, height: `${height}px`, width: `${width}px`}}
                       ref={ref => this.canvas = ref}/>
    }
}

