import {IMixerChannel} from "../_store";

export function addTrackAnalyser(gainNode: GainNode, context: AudioContext): { analyser: AnalyserNode, sampleBuffer: Float32Array } {
    const analyser = context.createAnalyser();
    // analyser.smoothingTimeConstant = 0.3;
    analyser.fftSize = 2048;
    const sampleBuffer = new Float32Array(analyser.fftSize);
    gainNode.connect(analyser);
    return {analyser, sampleBuffer};
}

export function calcNiceLevel({analyser, sampleBuffer}) {
    analyser.getFloatTimeDomainData(sampleBuffer);
    // Compute average power over the interval.
    // let sumOfSquares = 0;
    // for (let i = 0; i < sampleBuffer.length; i++) {
    //     sumOfSquares += sampleBuffer[i] ** 2;
    // }
    // const avgPowerDecibels = 10 * Math.log10(sumOfSquares / sampleBuffer.length);

    // Compute peak instantaneous power over the interval.
    let peakInstantaneousPower = 0;
    for (let i = 0; i < sampleBuffer.length; i++) {
        const power = sampleBuffer[i] ** 2;
        peakInstantaneousPower = Math.max(power, peakInstantaneousPower);
    }
    // peakInstantaneousPowerDecibels
    return 10 * Math.log10(peakInstantaneousPower);

    // Note that you should then add or subtract as appropriate to
    // get the _reference level_ suitable for your application.
    // Display value.
    // displayNumber('avg', avgPowerDecibels);
    // displayNumber('inst', peakInstantaneousPowerDecibels);
}
export function parseTrackLevel(trackName: string, tracks: any, isSoloed: boolean): number {
    const track = tracks.find(({name}) => name === trackName);
    return getTrackLevel(track, isSoloed);
}

/**
 *
 * @param channel
 * @param isSoloMode
 * @param isMuted
 */
export function getTrackLevel(channel: IMixerChannel, isSoloMode: boolean): number {
    //TODO no master hack
    return channel.level / 100 * (channel.solo ? 1 : ((channel.mute ? 0 : isSoloMode&&channel.type!=="master" ? 0 : 1)));
}



export const getAudioChannels = ({channels}: { channels: { [key: string]: IMixerChannel } }): Array<IMixerChannel> => Object.values(channels).filter(c => c.type === 'audio');