import { BarPos, TimeTrackData } from "../_reducers";

class TimeTrackError extends Error {}

function getBarPosFromStaticTempo(ms: number, data: TimeTrackData): BarPos {
  let [refBar, refTimeMs, bpm, beatPerBar, beatValue] = data;
  beatValue = beatValue || 4;
  beatPerBar = beatPerBar || 4;
  const sxthPerBeat = 16 / beatValue;
  // const sxthPerMs = bpm * sxthPerBeat;
  const barLengthMs = beatPerBar / (bpm / 60000);
  let refTimeOffset = ms - refTimeMs;
  //scale up so we don't have negative position
  const [timeMs, barOffset] = getTimeAndBarOffsetFromTime(
    refTimeOffset,
    barLengthMs,
    refBar
  );
  if (timeMs < 0) {
    throw new TimeTrackError("no negative times allowed: use overall offset");
  }
  const beatAbs = (bpm * timeMs) / 60000;
  const sxthAbs = beatAbs * sxthPerBeat;
  const beatCount = Math.floor(beatAbs);
  const bar = Math.floor(beatCount / beatPerBar);
  const beat = beatCount % beatPerBar;
  const sxth = Math.floor(sxthAbs % sxthPerBeat);
  const currentFraction = Math.round((sxthAbs % 1) * 1000);
  return [bar + barOffset, beat + 1, sxth + 1, currentFraction];
}

// function* rev(arr: number[]) {
//     for (let i = arr.length - 1; i >= 0; i--) {
//         yield arr[i]
//     }
// }

// for more precise rounding https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
export function getTimeSignatureFromTimeTrack(
  ms: number,
  timeTrack: Array<TimeTrackData>
): [number, number] {
  if (timeTrack.length === 1) {
    return getTimeSignaturesFromStaticTimeTrack(timeTrack[0]);
  } else {
    const indexAfter = timeTrack.findIndex(([, dataMs]) => dataMs > ms);
    return getTimeSignaturesFromStaticTimeTrack(
      timeTrack[Math.max(0, indexAfter - 1)]
    );
  }
}

export function getTimeSignaturesFromStaticTimeTrack(
  data: TimeTrackData
): [number, number] {
  let [, , , beatPerBar, beatValue] = data;
  beatValue = beatValue || 4;
  beatPerBar = beatPerBar || 4;
  return [beatPerBar, beatValue];
}

/**
 *
 *
 * @param ms
 * @param timeTrack
 */
export function getBarPosFromMs(
  ms: number,
  timeTrack: Array<TimeTrackData>
): BarPos {
  if (!timeTrack.length) {
    return undefined;
  }
  if (timeTrack.length === 1) {
    return getBarPosFromStaticTempo(ms, timeTrack[0]);
  } else {
    /**
     *  barNr, ms, tempo (default by next point), beats per measure (default 4), measure (default 4)
     */
    const definedPoint: TimeTrackData = timeTrack.find(
      (data) => data[1] === ms
    );
    if (definedPoint) {
      return [definedPoint[0], 1, 1, 0];
    }
    const nextDataIndex: number = timeTrack.findIndex((d) => d[1] > ms);
    if (nextDataIndex >= 0) {
      //If we have a reference before, use it to extra or interpolate
      const prevData = timeTrack[nextDataIndex - 1];
      if (prevData && prevData[2]) {
        //Tempo Data, nice
        return getBarPosFromStaticTempo(ms, prevData);
      } else {
        const nextData = timeTrack[nextDataIndex];
        if (!prevData) {
          //next data is first data
          if (nextData[2]) {
            return getBarPosFromStaticTempo(ms, nextData);
          }

          return [timeTrack[0][0], 1, 1, 0];
        } else {
          //need to get Tempo from next point
          const fullData = interpolateTempoByOffset(prevData, nextData);
          return getBarPosFromStaticTempo(ms, fullData);
        }
      }
    } else {
      //no later reference
      const lastData = timeTrack[timeTrack.length - 1];
      if (lastData[2]) {
        return getBarPosFromStaticTempo(ms, lastData);
      }
      const prevData = timeTrack[timeTrack.length - 2];
      const fullData = interpolateTempoByOffset(lastData, prevData);
      return getBarPosFromStaticTempo(ms, fullData);
    }
  }
}

function interpolateTempoByOffset(
  prevData: TimeTrackData,
  nextData: TimeTrackData,
  easing = "HAHA"
): TimeTrackData {
  let [refBar, refTimeMs, bpm, beatPerBar, beatValue] = prevData;
  let [nextBar, nextTimeMs] = nextData;
  if (bpm) {
    throw new TimeTrackError(
      "you don't interpolate tempo when it's already given in the prev data"
    );
  }
  beatPerBar = beatPerBar || 4;
  const beatDiff = (nextBar - refBar) * beatPerBar;
  const msDiff = nextTimeMs - refTimeMs;
  bpm = (beatDiff / msDiff) * 60000;
  return [refBar, refTimeMs, bpm, beatPerBar, beatValue];
}

/**
 * if there are times before bar 0 we just translate the ms and use a bigger offset for the displayed bar number
 * zero bar is always shown a bar 1
 * @param refTimeOffset
 * @param barLengthMs
 * @param refBar
 */
export function getTimeAndBarOffsetFromTime(
  refTimeOffset: number,
  barLengthMs: number,
  refBar: number
) {
  if (refTimeOffset >= 0) {
    return [refTimeOffset, refBar];
  }
  const barOffset = Math.floor(refTimeOffset / barLengthMs);
  return [refTimeOffset - barOffset * barLengthMs, barOffset + 1];
}

const isHigherDataPointThan = (pos: BarPos) => (data: TimeTrackData): boolean =>
  data[0] > pos[0];

export function getMsFromBarPos(
  pos: BarPos,
  timeTrack: Array<TimeTrackData>
): number {
  if (!timeTrack.length) {
    return undefined;
  }
  if (timeTrack.length === 1) {
    return getMsFromBarPosStaticTempo(pos, timeTrack[0]);
  } else {
    /**
     *  barNr, ms, tempo (default by next point), beats per measure (default 4), measure (default 4)
     */
    const [bar, beat, sxth, fraction] = pos;
    if (beat === 1 && sxth === 1 && fraction === 0) {
      const definedPoint: TimeTrackData = timeTrack.find(
        ([dataBar]) => dataBar === bar
      );
      if (definedPoint) {
        return definedPoint[1];
      }
    }
    const nextDataIndex: number = timeTrack.findIndex(
      isHigherDataPointThan(pos)
    );
    if (nextDataIndex > 0) {
      //We have a reference before, use it to extra- or interpolate
      const prevData = timeTrack[nextDataIndex - 1];
      if (prevData && prevData[2]) {
        //Tempo Data, nice
        return getMsFromBarPosStaticTempo(pos, prevData);
      } else {
        const nextData = timeTrack[nextDataIndex];
        if (!prevData) {
          if (nextData[2]) {
            return getMsFromBarPosStaticTempo(pos, nextData);
          }
          //No previous values
          return nextData[1];
        }
        //need to get Tempo from next point

        const fullData = interpolateTempoByOffset(prevData, nextData);
        return getMsFromBarPosStaticTempo(pos, fullData);
      }
    }
    //No next Values, all timetrackData is before current position
    else {
      const lastData = timeTrack[timeTrack.length - 1];
      if (lastData[2]) {
        return getMsFromBarPosStaticTempo(pos, lastData);
      }
      return timeTrack[timeTrack.length - 1][1];
    }
  }
}
export function nearestBar(pos: BarPos, timeTrack: TimeTrackData[]) {
  const exactMs = getMsFromBarPos(pos, timeTrack);
  const prevBar: BarPos = [pos[0], 1, 1, 0];
  const nextBar: BarPos = [pos[0] + 1, 1, 1, 0];
  const prevBarMs = getMsFromBarPos(prevBar, timeTrack);
  const nextBarMs = getMsFromBarPos(nextBar, timeTrack);
  if (Math.abs(prevBarMs - exactMs) < Math.abs(nextBarMs - exactMs)) {
    return prevBar;
  }
  return nextBar;
}

function getMsFromBarPosStaticTempo(pos: BarPos, data: TimeTrackData): number {
  const [bar, beat, sxth, fraction] = pos;
  let [refBar, refTimeMs, bpm, beatPerBar, beatValue] = data;
  beatValue = beatValue || 4;
  beatPerBar = beatPerBar || 4;
  // 4/4: 16/4 = 4   3/4 16/4 =12 6/8 = 6*16/8 = 12
  const sxthPerBeat = 16 / beatValue;
  // yes
  const beatPerMs = bpm / 60000;
  const sxthPerMs = beatPerMs * sxthPerBeat;
  const sxthCount =
    ((bar - refBar) * beatPerBar + beat - 1) * sxthPerBeat +
    sxth -
    1 +
    fraction / 1000;
  return Math.round(sxthCount / sxthPerMs + refTimeMs);
}
