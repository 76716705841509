import React, { useState } from "react";
import {
  WithStyles,
  withStyles,
  createStyles,
} from "@material-ui/core/styles/index";
import { Button, Theme } from "@material-ui/core";
import { secondsToString } from "../../_shared/utils";
import { BarPos, TimeTrackData } from "../../_reducers";
import {
  getBarPosFromMs,
  getMsFromBarPos,
  getTimeSignatureFromTimeTrack,
} from "../../_shared/timeUtils";
// import MusicNoteRoundedIcon from "@material-ui/icons/MusicNoteRounded";
import QueryBuilderRoundedIcon from "@material-ui/icons/QueryBuilderRounded";
import { fontFamilies } from "../../_shared/constants";
import SongPosTextField from "./SongPosTextField";
import { GridMode } from "../../_store";

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      fontFamily: `"${fontFamilies.monospace}", monospace`,
      display: "flex",
      justifyContent: "center",
    },
    container: {
      display: "flex",
      backgroundColor: palette.grey["800"],
      padding: 0,
      borderRadius: "0 0 3px",
    },
    timer: {
      padding: spacing(1),
      position: "relative",
      color: "white",
      width: "64px",
      textAlign: "center",
    },
    button: {
      borderRadius: "0 3px 3px 0",
      backgroundColor: "white",
      color: palette.grey["800"],
      borderColor: palette.grey["800"],
      // color: 'red'palette.grey
      "&:hover": {
        backgroundColor: palette.grey["200"],
      },
    },
  });

export function measureLabelForPos(
  songPosIns: number,
  timeTrack: Array<TimeTrackData>
) {
  return barPosToString(getBarPosFromMs(songPosIns * 1000, timeTrack), {
    showFraction: false,
  });
}

interface Props extends WithStyles<typeof styles> {
  songPosition: number;
  duration?: number;
  onChangeGridMode?: (gridMode: GridMode) => void;
  gridMode: GridMode;
  timeTrack: Array<TimeTrackData>;
  onSetPos: (pos: number) => void;
}

export const barPosToString = (
  [bar, measure, beat, fraction]: BarPos,
  options?: { showFraction: boolean }
) =>
  (!options?.showFraction
    ? [`${bar}`.padStart(3, " "), measure, beat]
    : [`${bar}`.padStart(3, " "), measure, beat, `${fraction}`.padStart(3, "0")]
  ).join(":");
const parseStartTime = (
  val: string,
  gridMode: GridMode,
  timeTrack: Array<TimeTrackData>
): number => {
  if (gridMode === "ms") {
    return parseFloat(val);
  } else {
    return getMsFromBarPos([parseInt(val), 1, 1, 0], timeTrack) / 1000;
  }
};

function SongPosition({
  timeTrack,
  // duration,
  songPosition,
  classes,
  onSetPos,
  onChangeGridMode,
  gridMode,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [pos, setPos] = useState("");
  const showMs = gridMode === "ms";
  const timeSignature =
    !showMs && getTimeSignatureFromTimeTrack(songPosition, timeTrack);
  let posLabel = showMs
    ? `${secondsToString(songPosition, false)}`
    : `${measureLabelForPos(songPosition, timeTrack)}`;
  // if(duration)posLabel+=showMs?`${secondsToString(duration, false)}`:`${measureLabelForPos(duration, timeTrack)}`;
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          className={classes.timer}
          onClick={() => {
            setIsEditing(true);
          }}
          onBlur={() => setIsEditing(false)}
        >
          {!isEditing && posLabel}
          {isEditing && (
            <SongPosTextField
              autoFocus
              type="text"
              label={posLabel}
              onChange={(e) => setPos(e.target.value)}
              value={pos}
              onBlur={(e) => {
                setIsEditing(false);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  setIsEditing(false);
                  onSetPos(
                    parseStartTime(pos, gridMode as GridMode, timeTrack)
                  );
                }
              }}
            />
          )}
        </div>
        {!!timeTrack.length && onChangeGridMode && (
          <Button
            variant={"outlined"}
            className={classes.button}
            onClick={() => onChangeGridMode(showMs ? "bar" : "ms")}
          >
            {showMs ? (
              <QueryBuilderRoundedIcon />
            ) : (
              `${timeSignature[0]}/${timeSignature[1]}`
            )}
          </Button>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(SongPosition);
