import React, { Component } from "react";
import { ITrackBuffer } from "../../TrackBufferRecord";
import WaveformLayer from "./WaveformLayer";
import { IMarker } from "../Player/MarkerRecord";
import { List } from "immutable";
import WaveformLabel from "./WaveformLabel";
import SongGrid from "./SongGrid";
import { TimeTrackData } from "../../_reducers";
import { withSongPosition } from "../../_shared/withSongPosition";
import { Loop, TimeLoop } from "../Player/LoopRecord";
import { GridMode } from "../../_store";
import MarkerCanvasView from "./MarkerCanvasView";

interface Props {
  tracks: Array<ITrackBuffer>;
  duration: number;
  timeTrack: Array<TimeTrackData>;
  height: number;
  songPosition: number;
  onPlayFrom: (time: number) => void;
  onChangeLoop: (loop: Loop) => void;
  marker: List<IMarker>;
  looping: boolean;
  loop: TimeLoop;
  gridMode: GridMode;
}

interface State {
  canvasCtx: CanvasRenderingContext2D | null;
  width: number;
  startPos: number;
}

const fillStyles = {
  headPos: "#0ff",
  canvasBg: "transparent",
  imageBg: "#272",
  test: "#ff0000",
};

export function songPositionToCanvasPosition({
  songPosition,
  duration,
  width,
}): number {
  const relativePos = songPosition / duration;
  return width * relativePos;
}

export function canvasPositionToSongPosition({ x, duration, width }): number {
  /*
     x= offset= 0
     x =
     */
  const canvasMaxTimeWidth = width;
  const xSongClick = Math.max(x, 0);
  const songPositionPercentage = xSongClick / canvasMaxTimeWidth;
  const songPosition = songPositionPercentage * duration;
  // console.log('SongClick', xSongClick, 'duration', duration, 'width', width, 'canvasMaxTimeWidth', canvasMaxTimeWidth, 'songPositionPercentage', songPositionPercentage, 'value', value);
  return Math.min(duration, songPosition);
}

export class SongView extends Component<Props, State> {
  playBarCanvas;
  songWrap;
  state: State = {
    canvasCtx: null,
    width: 100,
    startPos: 0,
  };

  constructor(props) {
    super(props);
    this.songWrap = React.createRef();
    this.playBarCanvas = React.createRef();
  }

  componentDidMount() {
    this.setCanvasWidth();
    window.addEventListener("resize", this.setCanvasWidth);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.setCanvasWidth);
  }

  setCanvasWidth = () => {
    const canvasCtx = this.playBarCanvas.current.getContext("2d");
    const width = this.songWrap.current.clientWidth;
    this.setState({ canvasCtx, width });
  };

  displayHead = (songPosition: number) => {
    const { width, canvasCtx, startPos } = this.state;
    const { height } = this.props;
    if (!canvasCtx) {
      return;
    }
    const absolutePos = songPositionToCanvasPosition({
      songPosition,
      duration: this.props.duration,
      width: this.state.width - startPos,
    });
    canvasCtx.strokeStyle = "#46a0ba";
    // console.log('draw at',relativePos,absolutePos);
    canvasCtx.fillStyle = fillStyles.headPos;
    canvasCtx.clearRect(0, 0, width, height);
    canvasCtx.fillRect(absolutePos - 1, 0, 2, height);
    canvasCtx.fillStyle = fillStyles.imageBg;
    canvasCtx.fillRect(0, 0, 1, height);
    canvasCtx.fillStyle = fillStyles.test;
    canvasCtx.fillRect(2349, 0, 1, height);
  };

  handlePositionClick = (e) => {
    const time = canvasPositionToSongPosition({
      x: e.nativeEvent.offsetX - this.state.startPos,
      duration: this.props.duration,
      width: this.state.width - this.state.startPos,
    });
    this.props.onPlayFrom(time);
  };

  render() {
    const {
      height,
      tracks,
      songPosition,
      timeTrack,
      duration,
      looping,
      loop,
      marker,
      gridMode,
      onChangeLoop,
    } = this.props;
    const { width } = this.state;
    if (duration) {
      this.displayHead(songPosition);
    }

    const hasTimeTrack = timeTrack && !!timeTrack.length;
    const gridHeight = height * 0.1;
    const fullGridHeight = hasTimeTrack ? 2 * gridHeight : gridHeight;
    const trackHeight = Math.floor((height - fullGridHeight) / tracks.length);
    return (
      <div
        style={{ position: "relative", height: `${height}px`, width: `100%` }}
        ref={this.songWrap}
      >
        {tracks.map(
          (t, index) =>
            t && (
              <WaveformLayer
                key={t.id}
                top={index * trackHeight + gridHeight}
                height={trackHeight}
                width={width}
                track={t}
              />
            )
        )}
        {tracks.map(
          (t, index) =>
            t && (
              <WaveformLabel
                key={t.id}
                top={index * trackHeight + gridHeight}
                height={trackHeight}
                width={width}
                track={t}
              />
            )
        )}
        <canvas
          width={width}
          height={height}
          ref={this.playBarCanvas}
          style={{
            backgroundColor: "rgba(0,0,0,0)",
            position: "absolute",
            top: 0,
            left: 0,
            height: `${height}px`,
            width: `${width}px`,
            zIndex: 10,
          }}
        />
        <MarkerCanvasView
          onPositionClick={this.handlePositionClick}
          onChangeLoop={onChangeLoop}
          width={width}
          height={height}
          gridMode={gridMode}
          loop={loop}
          marker={marker}
          looping={looping}
          duration={duration}
          timeTrack={timeTrack}
        />
        <SongGrid
          width={width}
          height={height}
          gridMode={gridMode}
          gridHeight={gridHeight}
          duration={duration}
          timeTrack={timeTrack}
          style={{ bottom: 0 }}
        />
      </div>
    );
  }
}

export default withSongPosition(SongView);
