import {call, put, takeLatest} from 'redux-saga/effects'
import * as api from '../_shared/api';
import {userConstants} from "../_shared/constants";

// worker Saga: will be fired on PLAYLIST_FETCH_REQUESTED actions
function* loginRequested(action) {
    try {
        const {password,username}=action;
        const result = yield call(()=>api.userLogin({password, username}));
        yield put({type: userConstants.LOGIN_SUCCESS,...result});
    } catch ({message}) {
        yield put({type: userConstants.LOGIN_FAILURE});
    }
}


function* loginSaga() {
    yield takeLatest(userConstants.LOGIN_REQUEST, loginRequested);
}

export default loginSaga;