import React, { useCallback } from "react";
import { Icon, Button } from "@material-ui/core";
import {
  AppState,
  changeLoopingAction,
  GridMode,
  LoopingState,
} from "../../_store";
import { connect } from "react-redux";
import { Loop } from "./LoopRecord";
import { BarPos, TimeTrackData } from "../../_reducers";
import { changeLoopAction } from "../../_actions/actions";
import { timeTrackSelector } from "../../_store/selector";
import PosInput from "./PosInput";
import { getBarPosFromMs, getMsFromBarPos } from "../../_shared/timeUtils";

interface Props {
  looping: LoopingState;
  loop: Loop;
  onChangeLooping: (l?: LoopingState) => void;
  onChangeLoop: (l: Loop) => void;
  timeTrack: Array<TimeTrackData>;
  gridMode: GridMode;
}
type MapValueFunction =
  | ((val: number | BarPos, type: "bar", timetrack: TimeTrackData[]) => BarPos)
  | ((val: number | BarPos, type: "ms", timetrack: TimeTrackData[]) => number);

export const mapValueToGridMode: MapValueFunction = (
  from,
  type: GridMode,
  timeTrack
) => {
  if (
    (type === "ms" && typeof from === "number") ||
    (type === "bar" && typeof from === "object")
  ) {
    return from;
  } else if (type === "ms" && typeof from === "object") {
    return getMsFromBarPos(from, timeTrack);
  } else if (type === "bar" && typeof from === "number") {
    return getBarPosFromMs(from, timeTrack);
  }
};

const LoopPlayControls: React.FC<Props> = ({
  looping,
  gridMode,
  onChangeLoop,
  timeTrack,
  loop,
  onChangeLooping,
}) => {
  const setStartLoop = useCallback(
    (from: number | BarPos) => {
      const time = mapValueToGridMode(from, loop.type as never, timeTrack);
      onChangeLoop({ ...loop, from: time } as Loop);
    },
    [onChangeLoop, loop, timeTrack]
  );

  const setEndLoop = useCallback(
    (to: number | BarPos) => {
      const time = mapValueToGridMode(to, loop.type as never, timeTrack);
      onChangeLoop({ ...loop, to: time } as Loop);
    },
    [onChangeLoop, loop, timeTrack]
  );

  return (
    <>
      <Button
        color={looping === LoopingState.single ? "primary" : "default"}
        variant={"contained"}
        disableElevation={true}
        onClick={() => onChangeLooping()}
      >
        <Icon>replay</Icon>
      </Button>
      <PosInput
        label={<span style={{ padding: "3px" }}>L</span>}
        value={loop.from}
        onSetPos={setStartLoop}
      />
      <PosInput
        label={<span style={{ padding: "3px" }}>R</span>}
        value={loop.to}
        onSetPos={setEndLoop}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  looping: state.looping,
  loop: state.loop,
  gridMode: state.gridMode,
  timeTrack: timeTrackSelector(state),
});
const mapDispatchToProps = (dispatch) => ({
  onChangeLooping: (looping?: LoopingState) => {
    dispatch(changeLoopingAction(looping));
  },
  onChangeLoop: (loop: Loop) => {
    dispatch(changeLoopAction(loop));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LoopPlayControls);
