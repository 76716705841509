import {call, put, takeLatest} from 'redux-saga/effects'
import * as api from '../_shared/api';
import {noteConstants} from "../_shared/constants";

// worker Saga: will be fired on PLAYLIST_FETCH_REQUESTED actions
function* fetchNotes(action) {
    try {
        const notes = yield call(api.fetchNotes);
        yield put({type: noteConstants.FETCH_SUCCESS, notes});
    } catch ({message}) {
        yield put({type: noteConstants.FETCH_FAILURE, message});
    }
}


function* notesSaga() {
    yield takeLatest(noteConstants.FETCH_REQUEST, fetchNotes);
}

export default notesSaga;