import {AppState, LoadProgress} from "../../_store";
import {CardHeader, LinearProgress} from "@material-ui/core";
import React from "react";
import {useSelector} from "react-redux";
import {ISongData} from "../../AudioBufferLoader";


const selectLoading = (state: AppState) => state.loading;
const selectSongs = (state: AppState) => state.songs;
function LoadProgressBar(){
    const loading=useSelector(selectLoading);
    const songs=useSelector(selectSongs);
    if(!loading){ return null;}
    const song =songs.get(loading.songId);
    if(!song){ return null;}
    return <div><CardHeader
        title={getLoadingLabel(loading,song )}/><LinearProgress
        variant="determinate" value={loading.completed[0] || 0}/>
        <LinearProgress color={'secondary'} variant="determinate" value={loading.completed[1] || 0}/></div>
}
function getLoadingLabel(loading: LoadProgress,song:ISongData) {
    if (!loading) {
        return '';
    }
    if (loading.completed[1] > 0) {
        return 'Preparing ' + song.name;
    } else return 'Loading ' + song.name;
}

export default LoadProgressBar;