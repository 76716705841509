import React from 'react';
import {Card, CardContent, createStyles, Divider, withStyles} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";

interface Props {
    notes?: Array<{ note: string }>
}

const styles = () =>
    createStyles({
        fillIt: {
            height: '100%'
        }
    });

// @ts-ignore
function NotesView({notes, classes}: Props) {
    return (<Card className={classes.fillIt}>
            <CardHeader title="Notes"/>
            <Divider variant="middle"/>
            <CardContent>
                {notes.map(({note}, index) => <span key={index}>{note}</span>)}
            </CardContent>
        </Card>
    );
}

export default withStyles(styles)(NotesView);