import {call, put, takeLatest,take} from 'redux-saga/effects'
import {songConstants} from "../_shared/constants";
import createLoadSongChannel from "./createLoadSongChannel";
import {loadSongSuccessAction, loadSongFailAction, loadSongProgressAction } from "../_store";

// worker Saga: will be fired on SONG_LOAD_REQUEST actions
function* loadSongRequested(action) {
    const {bufferLoader,song}=action.payload;
    console.log(`song ${song.id} needs to be loaded`);
    const channel = yield call(createLoadSongChannel, bufferLoader, song);
    while (true) {
        const { progress = 0, error, song ,songId} = yield take(channel);
        if (error) {
            yield put(loadSongFailAction( error));
            return;
        }
        if (song) {
            yield put(loadSongSuccessAction(song));
            return;
        }
        yield put(loadSongProgressAction({songId, progress}));
    }
}


function* loadSongSaga() {
    yield takeLatest(songConstants.LOAD_REQUEST, loadSongRequested);
}

export default loadSongSaga;