import { ISongData } from "../AudioBufferLoader";
import {
  noteConstants,
  playlistConstants,
  userConstants,
  markerConstants,
  playerConstants,
  songConstants,
} from "../_shared/constants";
import { GridMode, LoopingState, VoiceGroup } from "../_store";
import {
  Marker,
  PosMarkerRecord,
  TimeMarkerRecord,
} from "../container/Player/MarkerRecord";
import { Loop } from "../container/Player/LoopRecord";

export function selectSongAction({ id }) {
  return {
    type: songConstants.SELECT_SONG,
    payload: { id },
  };
}

export function addMarkerAction(songId: string, markerData: Marker) {
  const marker =
    markerData.type === "ms"
      ? new TimeMarkerRecord({
          ...markerData,
        })
      : new PosMarkerRecord({ ...markerData });
  return {
    type: markerConstants.ADD_REQUEST,
    songId,
    marker,
  };
}

export function updateMarkerAction(songId: string, marker) {
  return {
    type: markerConstants.UPDATE_REQUEST,
    songId,
    marker,
  };
}

export function deleteMarkerAction(songId, markerId) {
  return {
    type: markerConstants.DELETE_REQUEST,
    songId,
    markerId,
  };
}

export function playFromAction(payload) {
  return {
    type: playerConstants.PLAY_FROM,
    meta: {
      debounce: {
        time: 150,
        leading: true,
        trailing: false,
      },
    },
    payload,
  };
}

export function playFromSuccessAction(payload) {
  return {
    type: playerConstants.PLAY_FROM_SUCCESS,
    payload,
  };
}

export function resetPlayFromAction() {
  return {
    type: playerConstants.RESET_PLAY_FROM,
  };
}

export function updateChannelAction(payload) {
  return {
    type: playerConstants.UPDATE_CHANNEL,
    payload,
  };
}

export function updateChannelsAction(payload) {
  return {
    type: playerConstants.UPDATE_CHANNELS,
    payload,
  };
}

export function updateSongAction(payload) {
  return {
    type: songConstants.UPDATE,
    // meta: {
    //     debounce: {
    //         ms: 10, leading: true,
    //         trailing: true
    //     }
    // },
    payload,
  };
}

export function loadSongSuccessAction(song: ISongData) {
  return {
    type: songConstants.LOAD_SUCCESS,
    song,
  };
}

export function loadSongFailAction(error: any) {
  return {
    type: songConstants.LOAD_SUCCESS,
    error,
  };
}

export function loadSongProgressAction({ songId, progress }) {
  return {
    type: songConstants.LOAD_PROGRESS,
    payload: { songId, progress },
  };
}

export function startSongAction(payload) {
  return {
    payload,
    type: playerConstants.START_SONG,
  };
}

export function loadSongStartAction(payload) {
  return {
    payload,
    type: songConstants.LOAD_REQUEST,
  };
}

export function stopSongAction() {
  return {
    type: playerConstants.STOP_SONG,
  };
}
export function changeLoopAction(loop: Loop) {
  return {
    loop,
    type: playerConstants.CHANGE_LOOP,
  };
}

export function changeLoopingAction(looping?: LoopingState) {
  return {
    looping,
    type: playerConstants.CHANGE_LOOPING,
  };
}

export function changeGridModeAction(gridMode: GridMode) {
  return {
    gridMode,
    type: playerConstants.CHANGE_GRIDMODE,
  };
}

export function fetchCurrentUserAction() {
  return {
    type: userConstants.FETCH_REQUEST,
  };
}

export function fetchPlayListAction() {
  return {
    type: playlistConstants.FETCH_REQUEST,
  };
}

export function fetchNotesAction() {
  return {
    type: noteConstants.FETCH_REQUEST,
  };
}

export function logoutAction() {
  return {
    type: userConstants.LOGOUT,
  };
}

export function loginAction(credentials) {
  return {
    type: userConstants.LOGIN_REQUEST,
    ...credentials,
  };
}

export function selectGroupAction(group: VoiceGroup) {
  return {
    type: userConstants.SELECT_GROUP,
    group,
  };
}
